import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const UnitAssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  useEffect(() => {
    fetchUnitAssessment();
  }, [page, rowsPerPage, searchTerm]);

  const fetchUnitAssessment = async () => {
    try {
      const token = getToken();
      if(!token){
        console.log("Unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assessment/list?page=${page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        }
      );
      const data = await response.json();
      if (data?.data) {
        const assessmentWithSerial = data.data.map((assessment, index) => ({
          ...assessment,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !assessment.status, // Add this line
        }));
        setAssessments(assessmentWithSerial);
        setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  const deleteTeacher = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`, {
        method: "DELETE",
      });
      setSnackbarMessage("Teacher deleted successfully");
      setOpenSnackbar(true);
      fetchUnitAssessment();
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchUnitAssessment(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAddTeacher =() =>{
    navigate("/add-teacher")
  }

  return (
    <Box>
      <h2>Unit Assessment List</h2>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" padding={3} gap={1} >
          <TextField
            label="Search"
            variant="outlined"
            size="Large"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px", }}
          />
         
          <Box flexGrow={1} /> {/* This will push the button to the right */}
          <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAddTeacher}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ height: "50px", backgroundColor: "#e0e0e0" }}
          >
          Create
          </Button>
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Serial</b>
              </TableCell>
              <TableCell>
                <b>Unit Name</b>
              </TableCell>
              <TableCell>
                <b>Assessment Name</b>
              </TableCell>
              <TableCell>
                <b>Score</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.length > 0 ? (
              assessments.map((assessment) => (
                <TableRow key={assessment._id}>
                  <TableCell>{assessment.serial}</TableCell>
                  <TableCell>{assessment.teacher_id}</TableCell>
                  <TableCell>{assessment.assessment_id.assessment_title}</TableCell>
                  <TableCell>{assessment.score}</TableCell>
                  <TableCell>
                    
                    <IconButton
                      color="secondary"
                    //   onClick={() => viewAssessment(assessment._id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No teachers available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UnitAssessmentList;
