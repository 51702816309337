// src/components/FileUpload.js

import React, { useState } from 'react';
import axios from 'axios';

const ProjectSubmission = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    // Handle file input change
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/project`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage('File uploaded successfully!');
            console.log(response.data);
        } catch (error) {
            setMessage('Error uploading file.');
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div className="file-upload-container">
            <h1>UPLOAD YOUR PROJECT</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        type="file"
                        onChange={handleFileChange}
                    />
                </div>
                <button type="submit">Upload</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ProjectSubmission;
