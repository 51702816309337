import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AssignCourse = () => {
  const navigate = useNavigate();
  const [newTeacher, setNewTeacher] = useState({
    teacherCategory: "",
    teacherSubCategory: "",
    teacher: "",
    department: "",
    courses: [], // State for selected courses
  });

  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [courses, setCourses] = useState([]);

  // Fetch teacher categories
  useEffect(() => {
    const fetchTeacherCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teachercategory`
        );
        setTeacherCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchTeacherCategories();
  }, []);

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department`
        );
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  // Fetch teacher sub-categories
  useEffect(() => {
    const fetchTeacherSubCategories = async () => {
      if (!newTeacher.teacherCategory) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacher/sub/${newTeacher.teacherCategory}`
        );
        setTeacherSubCategories(response.data.data);
        setFilteredSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher sub-categories:", error);
      }
    };
    fetchTeacherSubCategories();
  }, [newTeacher.teacherCategory]);

  // Filter sub-categories based on selected teacher category
  useEffect(() => {
    if (newTeacher.teacherCategory) {
      setFilteredSubcategories(
        teacherSubCategories.filter(
          (sub) => sub.teacher_category_id === newTeacher.teacherCategory
        )
      );
    }
  }, [teacherSubCategories, newTeacher.teacherCategory]);

  // Fetch teachers based on sub-category
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacher`
        );
        const teacherData = response.data.data;
        const filteredTeachers = teacherData.filter(
          (item) => item.teacher_subCategoryId === newTeacher.teacherSubCategory
        );
        setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchTeachers();
  }, [newTeacher.teacherSubCategory]);

  // Fetch courses based on selected department
  useEffect(() => {
    const fetchCourses = async () => {
      if (!newTeacher.department) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/${newTeacher.department}`
        );
        setCourses(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [newTeacher.department]);

  // Handle course selection
  const handleCourseChange = (courseId) => {
    setNewTeacher((prevState) => {
      const selectedCourses = prevState.courses.includes(courseId)
        ? prevState.courses.filter((id) => id !== courseId) // Remove course if already selected
        : [...prevState.courses, courseId]; // Add course if not selected
      return { ...prevState, courses: selectedCourses };
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_course`,
        newTeacher
      );
      console.log(response.data);
      // Optionally handle success (e.g., show a message or redirect)
    } catch (err) {
      console.error(err);
      // Optionally handle error
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "grey.200",
          padding: "10px",
        }}
      >
        <Typography variant="h6">Assign Courses</Typography>
        <Button variant="contained" color="primary">
          + Create
        </Button>
      </Box>

      <Grid container spacing={1} sx={{ marginTop: "20px", width: "80%" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="#000">
            Select Teacher
          </Typography>

          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Teacher Category</InputLabel>
            <Select
              value={newTeacher.teacherCategory}
              onChange={(e) =>
                setNewTeacher({
                  ...newTeacher,
                  teacherCategory: e.target.value,
                })
              }
              label="Teacher Category"
              aria-required="true"
            >
              {teacherCategories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.teacher_category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Teacher Sub Category</InputLabel>
            <Select
              value={newTeacher.teacherSubCategory}
              onChange={(e) =>
                setNewTeacher({
                  ...newTeacher,
                  teacherSubCategory: e.target.value,
                })
              }
              label="Teacher Sub Category"
              aria-required="true"
              disabled={!newTeacher.teacherCategory}
            >
              {filteredSubcategories.map((subCategory) => (
                <MenuItem key={subCategory._id} value={subCategory._id}>
                  {subCategory.teacher_sub_category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Select Teacher</InputLabel>
            <Select
              value={newTeacher.teacher}
              onChange={(e) =>
                setNewTeacher({ ...newTeacher, teacher: e.target.value })
              }
              label="Teacher"
              aria-required="true"
              disabled={!newTeacher.teacherSubCategory}
            >
              {teacherFilter.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Department</InputLabel>
            <Select
              value={newTeacher.department}
              onChange={(e) =>
                setNewTeacher({ ...newTeacher, department: e.target.value })
              }
              label="Department"
              aria-required="true"
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="#000">
            Select Courses
          </Typography>
          {courses.length > 0 ? (
            courses.map((course) => (
              <Box key={course._id} display="flex" alignItems="center" mb={2}>
                <Checkbox
                  checked={newTeacher.courses.includes(course._id)}
                  onChange={() => handleCourseChange(course._id)}
                />
                <Typography sx={{ flex: 1 }}>{course.courses_name}</Typography>
              </Box>
            ))
          ) : (
            <Typography>
              No courses available for the selected department.
            </Typography>
          )}
        </Grid>
      </Grid>

      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AssignCourse;
