import { React, useState } from 'react';
import { Grid, Box, Container, Typography, Button, Avatar, Card, CardContent, CardMedia } from '@mui/material';
import Dashimg from "../../assets/dshimg.jpg";
import Right from "../../assets/right.png";
import Down from "../../assets/down.png";
import Robo from "../../assets/Robo.jpg";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CommentIcon from '@mui/icons-material/Comment';
import { MdCampaign } from 'react-icons/md';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import StarIcon from '@mui/icons-material/Star';

const circleData = [
  { title1: "COMPLETED", title2: "LESSONS", value1: 11, value2: 36 },
  { title1: "APPROVED", title2: "PROJECTS", value1: 2, value2: 8 },
  { title1: "AVERAGE SCORE", title2: "ASSESSMENTS", value1: 67, value2: 34 },
  { title1: "AVERAGE SCORE", title2: "QUIZZES", value1: 87, value2: 12 },
];

const CircleCard = ({ title1, title2, value1, value2 }) => {
  return (
    <Box
      sx={{
        width: { xs: "130px", sm: "150px", md: "160px" }, // Increased circle size
        height: { xs: "130px", sm: "150px", md: "160px" }, // Increased circle size
        position: "relative",
        // marginX: { xs: "8px", sm: "10px", md: "12px" }, // Adjusted margin
        marginTop: { xs: "10px", sm: "20px", md: "40px" },
        padding: "10px",
        display: "inline-block",
      }}
    >
      {/* Full circle */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "10px solid #fbe8df", // Border color for the circle
          position: "relative",
        }}
      >
        {/* Top half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#000", // Top half background (black)
            borderTopLeftRadius: "90px", // Adjusted for larger circle
            borderTopRightRadius: "90px", // Adjusted for larger circle
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
          }}
        >
          <Typography
            variant="h6"
            color="#fff !important"
            sx={{
              fontSize: { xs: "20px", sm: "24px", md: "25px" }, // Responsive font size for the value
              fontWeight: "300",
            }}
          >
            {value1}
          </Typography>
          <Typography
            variant="body2"
            color="#ff9933" // Color for title1
            sx={{
              fontSize: { xs: "13px" }, // Responsive font size for title1
              // fontWeight: "bold",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {title1}
          </Typography>
        </Box>

        {/* Bottom half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#bb4c4c", // Bottom half background (red)
            borderBottomLeftRadius: "90px", // Adjusted for larger circle
            borderBottomRightRadius: "90px", // Adjusted for larger circle
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Typography
            variant="body2"
            color="#fff !important" // Color for title1
            sx={{
              fontSize: { xs: "13px" }, // Responsive font size for title1
              // fontWeight: "bold",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {title2}
          </Typography>

          <Typography
            variant="h6"
            color="#fff!important"
            sx={{
              fontSize: { xs: "20px", sm: "24px", md: "25px" }, // Responsive font size for the value
              fontWeight: "400",
            }}
          >
            {value2}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const messages = [
  {
    id: 1,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "These questions aim to assess student engagement, ",
  },
  {
    id: 2,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "learning outcomes, and interest in robotics,",
  },
  {
    id: 3,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: " providing valuable feedback for improving the program.",
  },
];
const MessageCard = ({ user, time, text, isReply }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isReply ? "row-reverse" : "row", // Align based on reply or received
        justifyContent: isReply ? "flex-end" : "flex-start",
        marginBottom: 2,
        alignItems: "flex-start", // Ensures the avatar is at the top
      }}
    >
      {/* Avatar */}
      <Avatar
        alt={user}
        src="https://randomuser.me/api/portraits/women/65.jpg" // You can replace with dynamic avatars if needed
        sx={{ width: 48, height: 48, margin: isReply ? "0 0 0 16px" : "0 16px 0 0" }}
      />

      <Box sx={{ maxWidth: "75%" }}>
        {/* Name and Timestamp */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 0.5 }}>
          <Typography variant="subtitle2" fontWeight="bold">
            {user}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {time}
          </Typography>
        </Box>

        {/* Message Box */}
        <Box
          sx={{
            padding: 2,
            backgroundColor: isReply ? "#d1ffd6" : "#fff", // Different background for sent vs received
            borderRadius: "12px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            borderLeft: isReply ? "none" : "4px solid #FF6F61",
            borderRight: isReply ? "4px solid #FF6F61" : "none",
            wordWrap: "break-word",
          }}
        >
          <Typography variant="body2" color="text.primary">
            {text}
          </Typography>

        </Box>
      </Box>
    </Box>
  );
};
const StuDashboard = () => {
  const [view, setView] = useState("ongoing");
  return (
    <Container maxWidth="xl" sx={{ paddingY: 4, overflowX: 'hidden' }}>
      <Grid container spacing={2} >
        <Grid item xs={7}>
          <img src={Dashimg} alt="1" style={{ width: '100%', height: '350px', borderRadius: "18px" }} />

          <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: "20px", alignContent: "center" }}>
            <Box sx={{ borderRadius: "20px", border: "1px solid #fbe0d5", height: "250px", textAlign: "center", width: "100%" }}>
              {circleData.map((circle, index) => (
                <CircleCard
                  key={index}
                  title1={circle.title1}
                  title2={circle.title2}
                  value1={circle.value1}
                  value2={circle.value2}
                />
              ))}
            </Box>
          </Box>

          <Card sx={{
            display: 'flex',
            borderRadius: 2,
            backgroundColor: '#fce3d7',
            padding: 2,
            height: "170px",
            alignItems: 'center',
            marginTop: "20px",
            marginBottom:"20px",
            position: 'relative', // Enable relative positioning for the inner cards
            overflow: 'visible',  // Prevent the inner cards from getting clipped
          }}>
            {/* Left Side Icon Box */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f44e3b',
                borderRadius: '8px',
                padding: 2,
                width: 100,
                height: 100,
              }}
            >
              <Typography
                component="span"
                sx={{ color: 'white', fontSize: '40px', fontWeight: 'bold' }}
              >
                <MdCampaign size={80}></MdCampaign>
              </Typography>
            </Box>

            {/* Right Side Text and Image */}
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                  Robo Roundups
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Latest in Robotics
                </Typography>
              </Box>

              {/* Image */}
              <CardMedia
                component="img"
                sx={{ width: 200, height: 100, borderRadius: 2, ml: 'auto' }}
                image={Robo} // Replace this with your image
                alt="Robots working"
              />
            </Box>

            {/* Two Bottom Cards */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(10, 1fr)', // 10 columns for a 5-5 split
                gap: 2,
                position: 'absolute',
                bottom: '-50px', // Adjust to position half outside
                left: 0,
                right: 0,
                padding: 2,
                overflow: 'visible', // Prevent clipping
              }}
            >
              {/* First Inner Card */}
              <Box
                sx={{
                  gridColumn: 'span 5',
                  backgroundColor: '#fff',
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  transform: 'translateY(50%)', // Move half out of the main card
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 50, height: 50, borderRadius: '50%' }}
                  image={Dashimg} // Replace with your image
                  alt="First Card Image"
                />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="h6">First Card Title</Typography>
                  <Typography variant="body2">
                    This is the first inner card paragraph. It has two lines of text.
                  </Typography>
                </Box>
              </Box>

              {/* Second Inner Card */}
              <Box
                sx={{
                  gridColumn: 'span 5',
                  backgroundColor: '#fff',
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  transform: 'translateY(50%)', // Move half out of the main card
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 50, height: 50, borderRadius: '50%' }}
                  image={Dashimg} // Replace with your image
                  alt="Second Card Image"
                />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="h6">Second Card Title</Typography>
                  <Typography variant="body2">
                    This is the second inner card paragraph. It has two lines of text.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>



        </Grid>

        {/* Second column with xs={4} */}
        <Grid item xs={5}>
          <Grid container mb={3} sx={{ backgroundColor: "#f37c1f", height: "200px", padding: "50px", borderRadius: "20px" }}>
            <Box display="flex" justifyContent="center" sx={{ alignItems: "center" }}  >
              <Button
                variant="contained"
                onClick={() => setView("ongoing")}
                sx={{
                  marginRight: "10px",
                  backgroundColor: "orange",
                  color: "black",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                <img
                  src={Right}
                  alt="Ongoing Courses"
                  style={{ marginRight: "8px", width: "50px", height: "50px" }}
                />
                Ongoing Courses
              </Button>
              <Button
                variant="contained"
                onClick={() => setView("completed")}
                sx={{
                  marginRight: "10px",
                  backgroundColor: "black",
                  color: "#fff!important",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "15px",
                  // height: "90px",
                }}
              >
                <img
                  src={Down}
                  alt="Ongoing Courses"
                  style={{ marginRight: "8px", width: "50px", height: "50px" }}
                />
                Completed Courses
              </Button>
            </Box>
          </Grid>


          <Grid container alignItems="center" spacing={2} sx={{ marginBottom: 4 }}>
            {/* Icon in the first column */}
            <Grid item xs={2}>
              <Box
                sx={{
                  backgroundColor: "#FF6F61",
                  padding: "8px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <HistoryEduOutlinedIcon sx={{ color: "#fff", fontSize: 32 }} />
              </Box>
            </Grid>

            {/* Title and Stars in the second column */}
            <Grid item xs={10}>
              <Box
                sx={{
                  backgroundColor: "#FF6F61",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  gap: 1 // Space between title and stars
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#fff !important", marginRight: 1 }}
                >
                  Teacher's Remarks
                </Typography>
                {/* 5 Rating Stars */}
                {[...Array(5)].map((_, index) => (
                  <StarIcon sx={{ color: "#fff", fontSize: 13 }} />
                ))}
              </Box>
            </Grid>
          </Grid>
          {/* ); */}

          {/* const TeacherRemarks = () => ( */}
          <Grid sx={{ backgroundColor: "#fbe0d5", padding: "20px" }}>
            {/* Header Section */}
            {/* <TeacherRemarksHeader /> */}

            {/* Messages Section */}
            <Box>
              {messages.map((msg, index) => (
                <MessageCard
                  key={msg.id}
                  user={msg.user}
                  time={msg.time}
                  text={msg.text}
                  isReply={index % 2 === 1} // Assuming alternating between sent and received
                />
              ))}
            </Box>
          </Grid>


        </Grid>
      </Grid>
    </Container >
  );
};

export default StuDashboard;
