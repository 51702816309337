import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container } from 'react-bootstrap';

const AssessmentForm = () => {
    const [questions, setQuestions] = useState([
        {
            question_id: '',
            assessment_id: '',
            assessment_title: '',
            question_text: '',
            question_type: '',
            points: '',
            option_text_1: '',
            option_text_2: '',
            option_text_3: '',
            option_text_4: '',
            answer: '',
            is_correct: false,
        }
    ]);

    const [classes, setClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedChapter, setSelectedChapter] = useState('');
    const [assessmentTitle, setAssessmentTitle] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const classResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/classes`);
                setClasses(classResponse.data.data || []);
                
                const subjectResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`);
                setSubjects(subjectResponse.data.data || []);
                
                const chapterResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters`);
                setChapters(chapterResponse.data.data || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (index, event) => {
        const { name, value, type, checked } = event.target;
        const newQuestions = [...questions];
    
        if (type === 'checkbox') {
            newQuestions[index][name] = checked;
        } else {
            if (name === 'question_type') {
                newQuestions[index][name] = value;
    
                if (value === 'mcq') {
                    newQuestions[index].points = '1';
                } else {
                    newQuestions[index].points = '';
                }
            } else {
                newQuestions[index][name] = value;
            }
        }
        
        setQuestions(newQuestions);
    };

    const handleAddQuestion = () => {
        setQuestions([
            ...questions,
            {
                question_id: '',
                assessment_id: '',
                assessment_title: '',
                question_text: '',
                question_type: '',
                points: '',
                option_text_1: '',
                option_text_2: '',
                option_text_3: '',
                option_text_4: '',
                answer: '',
                is_correct: false,
            }
        ]);
    };

    const handleRemoveQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    const handleSubmit = async () => {
        try {
            const assessmentResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/api/assessment`, {
                class_id: selectedClass,
                course_id: selectedSubject,
                chapter_id: selectedChapter,
                assessment_name: assessmentTitle,
            });
    
            const assessmentId = assessmentResponse.data.data._id;
    
            const questionsResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/api/questions`, {
                assessment_id: assessmentId,
                questions,
            });
    
            alert('Questions and assessment submitted successfully!');
        } catch (error) {
            console.error('Error submitting questions and assessment:', error.response ? error.response.data : error.message);
            alert('Failed to submit questions and assessment.');
        }
    };
        
    return (
        <Container>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Create Assessment
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
                    <FormControl fullWidth>
                        <InputLabel>Select Class</InputLabel>
                        <Select
                            value={selectedClass}
                            onChange={(e) => setSelectedClass(e.target.value)}
                        >
                            {classes.length > 0 ? (
                                classes.map(cls => (
                                    <MenuItem key={cls._id} value={cls._id}>
                                        {cls.standard}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="">No classes available</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                            value={selectedSubject}
                            onChange={(e) => setSelectedSubject(e.target.value)}
                        >
                            {subjects.length > 0 ? (
                                subjects.map(sub => (
                                    <MenuItem key={sub._id} value={sub._id}>
                                        {sub.course_name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="">No subjects available</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Select Chapter</InputLabel>
                        <Select
                            value={selectedChapter}
                            onChange={(e) => setSelectedChapter(e.target.value)}
                        >
                            {chapters.length > 0 ? (
                                chapters.map(chap => (
                                    <MenuItem key={chap._id} value={chap._id}>
                                        {chap.course_name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="">No chapters available</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Assessment Title"
                        value={assessmentTitle}
                        onChange={(e) => setAssessmentTitle(e.target.value)}
                    />
                </Box>
    
                {questions.map((question, index) => (
                    <Box key={index} sx={{ marginBottom: 3, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                        <TextField
                            fullWidth
                            name="question_text"
                            label="Question Text"
                            value={question.question_text}
                            onChange={(e) => handleChange(index, e)}
                            sx={{ marginBottom: 2 }}
                        />
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel>Question Type</InputLabel>
                            <Select
                                name="question_type"
                                value={question.question_type}
                                onChange={(e) => handleChange(index, e)}
                            >
                                <MenuItem value="mcq">MCQ</MenuItem>
                                <MenuItem value="descriptive">Descriptive</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            name="points"
                            label="Points"
                            value={question.points}
                            onChange={(e) => handleChange(index, e)}
                            sx={{ marginBottom: 2 }}
                        />
                        {question.question_type === 'mcq' && (
                            <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: '1fr 1fr' }}>
                                <TextField
                                    name="option_text_1"
                                    label="Option 1"
                                    value={question.option_text_1}
                                    onChange={(e) => handleChange(index, e)}
                                />
                                <TextField
                                    name="option_text_2"
                                    label="Option 2"
                                    value={question.option_text_2}
                                    onChange={(e) => handleChange(index, e)}
                                />
                                <TextField
                                    name="option_text_3"
                                    label="Option 3"
                                    value={question.option_text_3}
                                    onChange={(e) => handleChange(index, e)}
                                />
                                <TextField
                                    name="option_text_4"
                                    label="Option 4"
                                    value={question.option_text_4}
                                    onChange={(e) => handleChange(index, e)}
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Correct Answer</InputLabel>
                                    <Select
                                        name="answer"
                                        value={question.answer}
                                        onChange={(e) => handleChange(index, e)}
                                    >
                                        <MenuItem value="option_text_1">{question.option_text_1}</MenuItem>
                                        <MenuItem value="option_text_2">{question.option_text_2}</MenuItem>
                                        <MenuItem value="option_text_3">{question.option_text_3}</MenuItem>
                                        <MenuItem value="option_text_4">{question.option_text_4}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                        {question.question_type === 'descriptive' && (
                            <TextField
                                fullWidth
                                name="answer"
                                label="Answer"
                                multiline
                                rows={4}
                                value={question.answer}
                                onChange={(e) => handleChange(index, e)}
                                sx={{ marginBottom: 2 }}
                            />
                        )}
                        <IconButton onClick={() => handleRemoveQuestion(index)} color="error">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}
                <Button variant="contained" color="primary" onClick={handleAddQuestion} sx={{ marginRight: 2 }}>
                    Add Another Question
                </Button>
                <Button variant="contained" color="secondary" onClick={handleSubmit}>
                    Submit Questions and Assessment
                </Button>
            </Box>
        </Container>
    );
};

export default AssessmentForm;
