import React, { useState } from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, Box, Button, Typography, Paper, Divider } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import ReactQuill styles
import axios from 'axios';

const saveContent = async (contentSections) => {
  const formData = new FormData();

  contentSections.forEach((section, index) => {
    formData.append(`contentSections[${index}][type]`, section.type);
    formData.append(`contentSections[${index}][data]`, section.data || '');

    if (section.galleryFiles) {
      for (let i = 0; i < section.galleryFiles.length; i++) {
        formData.append(`contentSections[${index}][galleryFiles][]`, section.galleryFiles[i]);
      }
    }

    if (section.videoFile) {
      formData.append(`contentSections[${index}][videoFile]`, section.videoFile);
    }

    if (section.videoUrl) {
      formData.append(`contentSections[${index}][videoUrl]`, section.videoUrl);
    }

    if (section.imageFile) {
      formData.append(`contentSections[${index}][imageFile]`, section.imageFile);
    }
  });

  try {
    const response = await axios.post('http://localhost:8080/upload/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Success:', response.data);
  } catch (error) {
    console.error('Error:', error);
  }
};

const DynamicContentForm = () => {
  const [contentSections, setContentSections] = useState([]);

  const handleSave = () => {
    saveContent(contentSections);
  };

  const handleContentTypeChange = (event) => {
    const { value } = event.target;
    setContentSections((prevSections) => [
      ...prevSections,
      { type: value, id: new Date().getTime(), data: '' },
    ]);
  };

  const handleParagraphChange = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };

  const handleGalleryChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].galleryFiles = event.target.files;
    setContentSections(updatedSections);
  };

  const handleVideoUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].videoUrl = value;
    setContentSections(updatedSections);
  };

  const handleVideoUploadChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].videoFile = event.target.files[0];
    setContentSections(updatedSections);
  };

  const handleSingleImageChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].imageFile = event.target.files[0];
    setContentSections(updatedSections);
  };

  return (
    <Box sx={{ maxWidth: '1000px', margin: '0 auto', padding: 3 , marginLeft:"0px"}}>
      {/* <Paper elevation={3} sx={{ padding: 3 }}> */}
        <Typography variant="h5" gutterBottom>Dynamic Content Form</Typography>
        <Divider sx={{ marginBottom: 3 }} />

        {/* Main Select to choose content type */}
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Choose content</InputLabel>
          <Select
            label="Choose content"
            onChange={handleContentTypeChange}
            sx={{ marginBottom: 3 }}
          >
            <MenuItem value="paragraph">Paragraph</MenuItem>
            <MenuItem value="gallery">Gallery</MenuItem>
            <MenuItem value="videoUpload">Video Upload</MenuItem>
            <MenuItem value="videoUrl">Video URL</MenuItem>
            <MenuItem value="singleImage">Single Image</MenuItem>
          </Select>
        </FormControl>

        {/* Dynamically render fields based on selected content types */}
        {contentSections.map((section, index) => {
          switch (section.type) {
            case 'paragraph':
              return (
                <Box key={section.id} sx={{ marginBottom: 3 }}>
                  <ReactQuill
                    theme="snow"
                    value={section.data}
                    onChange={(value) => handleParagraphChange(index, value)}
                    style={{ minHeight: '200px' }}
                  />
                </Box>
              );
            case 'gallery':
              return (
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ multiple: true, accept: 'image/*' }}
                  onChange={(e) => handleGalleryChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              );
            case 'videoUpload':
              return (
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'video/*' }}
                  onChange={(e) => handleVideoUploadChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              );
            case 'videoUrl':
              return (
                <TextField
                  key={section.id}
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleVideoUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              );
            case 'singleImage':
              return (
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/*' }}
                  onChange={(e) => handleSingleImageChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              );
            default:
              return null;
          }
        })}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ marginTop: 3, width: '100%' }}
        >
          Save
        </Button>
      {/* </Paper> */}
    </Box>
  );
};

export default DynamicContentForm;
