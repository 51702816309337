import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  TablePagination,
  TextField,
  IconButton,
  Switch,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const CoursesTable = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems1, setTotalItems] = useState([])

  useEffect(() => {
    fetchCourses();
  }, [page, rowsPerPage, searchTerm]);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/courses?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      const data = await response.json();
      if (data?.data) {
        const coursesWithSerial = data.data.map((course, index) => ({
          ...course,
          serial: index + 1
        }));
        setCourses(coursesWithSerial);
        console.log(data.pagination.totalItems);
        setTotalItems(data.pagination.totalItems)
        
      } else {
        setError("No courses found");
      }
    } catch (err) {
      setError("Failed to fetch courses");
      console.error("Error fetching courses:", err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteCourse = async (id) => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/course/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      setSnackbarMessage("Course deleted successfully");
      setOpenSnackbar(true);
      fetchCourses(); // Refresh the courses list after deletion
    } catch (err) {
      setError(err.message || 'Failed to delete course');
      console.error("Error deleting course:", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("newPage",newPage);
    
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };
  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateCourseStatus(id, newStatus); // Update parent status in the backend
  };
  const updateCourseStatus = async (id, status) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/courses/${id}/status_course`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });
      setSnackbarMessage('Course status updated successfully');
      setOpenSnackbar(true);
      fetchCourses(); // Refresh the table after status change
    } catch (err) {
      setError('Failed to update Course status');
    }
  };
  return (
    <div>
      <h2>Courses</h2>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        {/* <Button
          component={Link}
          to="/course"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Course
        </Button> */}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>S.N.</b></TableCell>
              <TableCell><b>Course Name</b></TableCell>
              <TableCell><b>Course details</b></TableCell>
              <TableCell><b>Number of Chapters</b></TableCell>
              {/* <TableCell><b>Status</b></TableCell> */}
              {/* <TableCell><b>Actions</b></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : courses.length > 0 ? (
              courses.map((course) => (
                <TableRow key={course._id}>
                  <TableCell>{course.serial}</TableCell>
                  <TableCell>{course.courses_name}</TableCell>
                  <TableCell>{course.course_details}</TableCell>
                  <TableCell>{course.no_of_chapters}</TableCell>
                  {/* <TableCell>
                  <Switch
                      checked={course.status}
                      onChange={() => handleStatusToggle(course._id, course.status)}
                      color="primary"
                    />
                  </TableCell> */}
                  {/* <TableCell>
                    <IconButton
                      color="primary"
                      component={Link}
                      to={`/edit_course/${course._id}`}
                      
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => deleteCourse(course._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No courses available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems1>10 &&<Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TablePagination
          component="div"
          count={totalItems1}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
      </Box>}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CoursesTable;
