// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams, useNavigate } from "react-router-dom";
// import {
//   TextField, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem
// } from "@mui/material";

// const UpdateQuestionForm = () => {
//   const { id } = useParams(); // Get the question ID from the URL
//   const navigate = useNavigate(); // For navigation

//   const [question, setQuestion] = useState(null);
//   const [questionText, setQuestionText] = useState("");
//   const [questionType, setQuestionType] = useState("MCQ");
//   const [options, setOptions] = useState({
//     option_text_1: "",
//     option_text_2: "",
//     option_text_3: "",
//     option_text_4: "",
//   });
//   const [answer, setAnswer] = useState("");
//   const [points, setPoints] = useState("");

//   useEffect(() => {
//     if (!id) {
//       console.error("No ID found in URL parameters");
//       return;
//     }

//     // Fetch the current question details using the ID from the URL
//     const fetchQuestion = async () => {
//       try {
//         const response = await axios.get(`http://localhost:8080/api/questions`);
//         const question = response.data.data;
//         setQuestion(question);

//         // Set the form fields with the fetched data
//         setQuestionText(question.question_text || "");
//         setQuestionType(question.question_type || "MCQ");
//         setOptions({
//           option_text_1: question.option_text_1 || "",
//           option_text_2: question.option_text_2 || "",
//           option_text_3: question.option_text_3 || "",
//           option_text_4: question.option_text_4 || "",
//         });
//         setAnswer(question.answer || "");
//         setPoints(question.points || "");
//       } catch (error) {
//         console.error("Failed to fetch question", error);
//       }
//     };

//     fetchQuestion();
//   }, [id]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!id) {
//       console.error("No ID found for updating the question");
//       return;
//     }

//     const updatedQuestion = {
//       question_text: questionText.trim(),
//       question_type: questionType,
//       points: parseInt(points, 10) || 0, // Default to 0 if invalid
//       ...options,
//       answer: answer.trim(),
//     };

//     try {
//       console.log("Updating question with ID:", id);
//       console.log("Updated question data:", updatedQuestion);

//       await axios.put(`http://localhost:8080/api/questions/${id}`, updatedQuestion);
//       alert("question updated successfully")
//     } catch (error) {
//       console.error("Failed to update question", error);
//       alert(`Failed to update question: ${error.message}`);
//     }
//   };

//   return (
//     <Box sx={{ padding: 3 }}>
//       <Typography variant="h4" gutterBottom>
//         Update Question
//       </Typography>
//       {question ? (
//         <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//           <TextField
//             label="Question Text"
//             value={questionText}
//             onChange={(e) => setQuestionText(e.target.value)}
//             fullWidth
//             required
//           />
//           <FormControl fullWidth>
//             <InputLabel>Question Type</InputLabel>
//             <Select
//               value={questionType}
//               onChange={(e) => setQuestionType(e.target.value)}
//               label="Question Type"
//             >
//               <MenuItem value="MCQ">MCQ</MenuItem>
//               <MenuItem value="Descriptive">Descriptive</MenuItem>
//             </Select>
//           </FormControl>
//           {questionType === "MCQ" && (
//             <>
//               <TextField
//                 label="Option 1"
//                 value={options.option_text_1}
//                 onChange={(e) => setOptions({ ...options, option_text_1: e.target.value })}
//                 fullWidth
//               />
//               <TextField
//                 label="Option 2"
//                 value={options.option_text_2}
//                 onChange={(e) => setOptions({ ...options, option_text_2: e.target.value })}
//                 fullWidth
//               />
//               <TextField
//                 label="Option 3"
//                 value={options.option_text_3}
//                 onChange={(e) => setOptions({ ...options, option_text_3: e.target.value })}
//                 fullWidth
//               />
//               <TextField
//                 label="Option 4"
//                 value={options.option_text_4}
//                 onChange={(e) => setOptions({ ...options, option_text_4: e.target.value })}
//                 fullWidth
//               />
//             </>
//           )}
//           <TextField
//             label="Answer"
//             value={answer}
//             onChange={(e) => setAnswer(e.target.value)}
//             fullWidth
//             required
//           />
//           <TextField
//             label="Points"
//             value={points}
//             onChange={(e) => setPoints(e.target.value)}
//             fullWidth
//           />
//           <Button type="submit" variant="contained" color="primary">
//             Update Question
//           </Button>
//         </Box>
//       ) : (
//         <Typography>Loading...</Typography>
//       )}
//     </Box>
//   );
// };

// export default UpdateQuestionForm;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";

const UpdateQuestionForm = () => {
  const { id } = useParams(); // Get the question ID from the URL
  const navigate = useNavigate(); // For navigation

  const [question, setQuestion] = useState(null);
  const [questionText, setQuestionText] = useState("");
  const [questionType, setQuestionType] = useState("MCQ");
  const [options, setOptions] = useState({
    option_text_1: "",
    option_text_2: "",
    option_text_3: "",
    option_text_4: "",
  });
  const [answer, setAnswer] = useState("");
  const [points, setPoints] = useState("");

  useEffect(() => {
    if (!id) {
      console.error("No ID found in URL parameters");
      return;
    }

    // Fetch the current question details using the ID from the URL
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/questions/${id}`);
        const question = response.data.data;
        setQuestion(question);

        // Set the form fields with the fetched data
        setQuestionText(question.question_text || "");
        setQuestionType(question.question_type || "MCQ");
        setOptions({
          option_text_1: question.option_text_1 || "",
          option_text_2: question.option_text_2 || "",
          option_text_3: question.option_text_3 || "",
          option_text_4: question.option_text_4 || "",
        });
        setAnswer(question.answer || "");
        setPoints(question.points || "");
      } catch (error) {
        console.error("Failed to fetch question", error);
      }
    };

    fetchQuestion();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!id) {
      console.error("No ID found for updating the question");
      return;
    }

    const updatedQuestion = {
      question_text: questionText.trim(),
      question_type: questionType,
      points: parseInt(points, 10) || 0, // Default to 0 if invalid
      ...options,
      answer: answer.trim(),
    };

    try {
      console.log("Updating question with ID:", id);
      console.log("Updated question data:", updatedQuestion);

      await axios.put(`${process.env.REACT_APP_BACKEND}/api/questions/${id}`, updatedQuestion);
      alert("Question updated successfully");
      navigate(`/questions/${id}`); // Redirect to the updated question page
    } catch (error) {
      console.error("Failed to update question", error);
      alert(`Failed to update question: ${error.message}`);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Update Question
      </Typography>
      {question ? (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Question Text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            fullWidth
            required
          />
          <FormControl fullWidth>
            <InputLabel>Question Type</InputLabel>
            <Select
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              label="Question Type"
            >
              <MenuItem value="MCQ">MCQ</MenuItem>
              <MenuItem value="Descriptive">Descriptive</MenuItem>
            </Select>
          </FormControl>
          {questionType === "MCQ" && (
            <>
              <TextField
                label="Option 1"
                value={options.option_text_1}
                onChange={(e) => setOptions({ ...options, option_text_1: e.target.value })}
                fullWidth
              />
              <TextField
                label="Option 2"
                value={options.option_text_2}
                onChange={(e) => setOptions({ ...options, option_text_2: e.target.value })}
                fullWidth
              />
              <TextField
                label="Option 3"
                value={options.option_text_3}
                onChange={(e) => setOptions({ ...options, option_text_3: e.target.value })}
                fullWidth
              />
              <TextField
                label="Option 4"
                value={options.option_text_4}
                onChange={(e) => setOptions({ ...options, option_text_4: e.target.value })}
                fullWidth
              />
            </>
          )}
          <TextField
            label="Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Points"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            Update Question
          </Button>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Box>
  );
};

export default UpdateQuestionForm;
