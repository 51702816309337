import React from "react";
import { Box, MenuItem, Select, Typography, Grid, Avatar } from "@mui/material";
import Stack from "@mui/material/Stack";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

// Sample messages data
const messages = [
  {
    id: 1,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "These questions aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
  {
    id: 2,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "These questions aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
  {
    id: 3,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "Aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
];

// Top section buttons data
const topButtons = [
  { label: "7 Grades", color: "#e4443f" },
  { label: "450 Students", color: "#e4443f" },
  { label: "10 Courses", color: "#e4443f" },
];

// Message Card Component
const MessageCard = ({ user, time, text, isReply }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isReply ? "flex-end" : "flex-start",
        marginBottom: 2,
      }}
    >
      {!isReply && (
        <Avatar
          alt={user}
          src="https://randomuser.me/api/portraits/women/65.jpg"
          sx={{ width: 48, height: 48, marginRight: 2 }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: isReply ? "row-reverse" : "row",
          gap: 2,
          alignItems: "center",
          padding: 2,
          backgroundColor: "#f4f4f4",
          borderRadius: "8px",
          maxWidth: "75%",
          position: "relative",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {isReply && (
          <Avatar
            alt={user}
            src="https://randomuser.me/api/portraits/women/65.jpg"
            sx={{ width: 48, height: 48 }}
          />
        )}

        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle2" fontWeight="bold">
              {user.toUpperCase()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {time}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ marginTop: "4px" }}>
            {text}
          </Typography>
        </Box>

        <ChatBubbleOutlineIcon
          sx={{
            position: "absolute",
            top: "-8px",
            left: isReply ? "unset" : "-24px",
            right: isReply ? "-24px" : "unset",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "2px",
            border: "1px solid #ccc",
            fontSize: "24px",
            color: "#666",
          }}
        />
      </Box>
    </Box>
  );
};

// Main TeacherComment Component
const TeacherComment = () => {
  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#000",
            width: "73%",
            fontWeight: "bold",
            backgroundColor: "#e4e1de",
            padding: "6px",
            textAlign: "center",
          }}
        >
          PRODUCT ASSIGNED TO{" "}
        </Typography>

        {/* Top Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          {topButtons.map((button, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: button.color,
                color: "#fff",
                fontSize: "17px",
                padding: "8px 10px",
                textAlign: "center",
                width: "130px",
                borderRadius: "4px",
              }}
            >
              {button.label}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Dropdown Selectors */}
      <Grid container spacing={3} marginBottom={2}>
        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Grade</MenuItem>
            <MenuItem value={1}>Grade 1</MenuItem>
            <MenuItem value={2}>Grade 2</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Division</MenuItem>
            <MenuItem value="A">Division A</MenuItem>
            <MenuItem value="B">Division B</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ backgroundColor: "#f4e3c9" }}
          >
            <MenuItem value="">
              <em>Select Student</em>
            </MenuItem>
            <MenuItem value="Math">Math</MenuItem>
            <MenuItem value="Science">Science</MenuItem>
          </Select>
        </Grid>
      </Grid>

      {/* Messages Section */}
      <Box>
        {messages.map((msg, index) => (
          <MessageCard
            key={msg.id}
            user={msg.user}
            time={msg.time}
            text={msg.text}
            isReply={index % 2 === 1} // Alternating reply and normal message
          />
        ))}
      </Box>
    </Box>
  );
};

export default TeacherComment;
