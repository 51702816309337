import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Rating,
  Grid,
  Pagination,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Eye icon
import bann from "../../../assets/1234.png";

const ProjectPage = () => {
  // Sample data for projects
  const [projects, setProjects] = useState([
    { id: 1, name: "Project A", student: "Student 1", rating: 0, class: "6" },
    { id: 2, name: "Project B", student: "Student 2", rating: 4.5, class: "5" },
    { id: 3, name: "Project C", student: "Student 3", rating: 5, class: "9" },
    // More projects...
  ]);

  const [page, setPage] = useState(1);
  const navigate = useNavigate(); // Initialize useNavigate

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRatingChange = (id, newRating) => {
    const updatedProjects = projects.map((project) =>
      project.id === id ? { ...project, rating: newRating } : project
    );
    setProjects(updatedProjects);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      {/* Header with Add Project and Search */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <TextField
          label="Search Projects"
          variant="outlined"
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/projectform"
        >
          Add Project
        </Button>
      </Box>

      {/* List of Project Cards */}
      <Grid container spacing={2}>
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <Card
              sx={{
                backgroundColor: "#fff",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
              }}
            >
              <CardContent sx={{ p: 2 }}>
                {/* Image Section */}
                <Box
                  component="img"
                  src={bann} // Replace with actual image source if needed
                  alt={project.name}
                  sx={{
                    width: "100%",
                    height: "200px",
                    borderRadius: "12px",
                    mb: 2,
                  }}
                />

                {/* Product Name and Info */}
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {project.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 1 }}
                >
                  Uploaded by: {project.student}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 1 }}
                >
                  Class {project.class}th
                </Typography>

                {/* Rating Section */}
                <Rating
                  name={`rating-${project.id}`}
                  value={project.rating}
                  precision={0.5}
                  onChange={(event, newValue) =>
                    handleRatingChange(project.id, newValue)
                  }
                />
              </CardContent>

              {/* View Project Button (Eye Icon) */}
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  color: "#1976d2",
                }}
                onClick={() => navigate("/projectview/:id")} // Navigate to the ProjectView page
              >
                <VisibilityIcon /> {/* Eye icon */}
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination at the Bottom Left */}
      <Box mt={4} display="flex" justifyContent="flex-start">
        <Pagination
          count={10}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default ProjectPage;
