// import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate, Link } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   Axios.defaults.withCredentials = true;
  //   Axios.get(`${process.env.REACT_APP_BACKEND}/auth/verify`)
  //     .then((res) => {
  //       if (res.data.status) {
  //         console.log(res.data);
  //       } else {
  //         navigate("/");
  //       }
  //     })
  //     .catch((err) => {
  //       // Handle network or server errors
  //       console.error("Error during verification:", err.message || err);
  //       navigate("/"); // Optionally navigate back to login on error
  //     });
  // }, [navigate]);

  const handleLogout = async (event) => {
    event.preventDefault();
    Axios.get(`${process.env.REACT_APP_BACKEND}/auth/logout`)
      .then((res) => {
        if (res.data.status) {
          navigate("/");
        }
      })
      .catch((err) => {
        console.error("Error during logout:", err.message || err);
      });
  };

  return (
    <div>
      <div>Home</div><br />
      <Link to="/company">Company</Link> <br /><br />
      <Link to="/teacher">Teacher</Link><br /><br />
      <Link to="/teacher/rolepermission">Teacher rolepermission</Link><br /><br />
      <Link to="/student">student</Link><br /><br />
      <br />
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Home;
