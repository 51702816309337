import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const AssignProduct = () => {
  const navigate = useNavigate();
  const [newProduct, setNewProduct] = useState({
    resource_person: "",
    // department: "",
    department_id: "", // Initialize grade_id state
    product_ids: [],
  });

  const [error, setError] = useState(""); // State for error handling
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [products, setProducts] = useState([]);
  const [alreadySelectedProducts, setAlreadySelectedProducts] = useState([]);
  const [resourcePerson, setResourcePerson] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch teachers based on sub-category
  useEffect(() => {
    const fetchResourcePerson = async () => {
      try {
        const token = getToken();
        if(!token){
          console.log("Access Denied")
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/getallresourceperson`,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }

        );
        const resourcePersonData = response.data.data;
        setResourcePerson(resourcePersonData || []);
        console.log("resoursepersons: ", response.data.data);

        // const filteredTeachers = resourcePersonData.filter(
        //   (item) => item.teacher_subCategoryId === newProduct.teacherSubCategory
        // );
        // setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchResourcePerson();
  }, []);

  useEffect(() => {
    const fetchAllgrades = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/grade`
      );

      const data = await response.json();
      if (response.ok) {
        setAllGrades(data.data || []); // Set grades from the response
      }
    };
    fetchAllgrades();
  }, []);

  // Fetch courses based on selected department
  useEffect(() => {
    const fetchProducts = async () => {
      // if (!newProduct.grade_id) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/allproducts`
        );
        // setProducts(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchProducts();
  }, []);

  // already selected products by resourceperson
  useEffect(() => {
    const fetchProductsbyresourceperson = async () => {
      if (!newProduct.resource_person && !newProduct.department_id){
        return;
      }
      try {
        console.log("department Id   :",newProduct.department_id);
        
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/productsByResoursePerson/${newProduct.resource_person}/byDept/${newProduct.department_id}`
        );
        setAlreadySelectedProducts(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchProductsbyresourceperson();
  }, [newProduct.resource_person, newProduct.department_id]);

  // fetch products by department
  useEffect(() => {
    const fetchProductsByDept = async () => {
      if (!newProduct.department_id) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/byDept/${newProduct.department_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setProducts(data.data || []);
          console.log("Courses related gradeId: ", data.data);
          setNewProduct.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchProductsByDept();
  }, [newProduct.department_id]);

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department`
        );
        console.log(response.data); // Log the data to check its structure
        if (Array.isArray(response.data.data)) {
          setDepartments(response.data.data || []);
        } else {
          console.error("Expected an array but received:", response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching departments", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  // Handle course selection
  const handleCourseChange = (courseId) => {
    setNewProduct((prevState) => {
      const selectedCourses = prevState.courses.includes(courseId)
        ? prevState.courses.filter((id) => id !== courseId) // Remove course if already selected
        : [...prevState.courses, courseId]; // Add course if not selected
      return { ...prevState, courses: selectedCourses };
    });
  };

  // Handle class selection
  const handleClassChange = (gradeId, division) => {
    setNewProduct((prevState) => {
      const currentGrades = [...prevState.grades];
      const index = currentGrades.findIndex((grade) => grade.id === gradeId);
      if (index > -1) {
        // If already selected, remove it
        currentGrades.splice(index, 1);
      } else {
        // Add if not selected
        currentGrades.push({ id: gradeId, division });
      }
      return { ...prevState, grades: currentGrades };
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    const token = getToken();
    if (!token) {
      console.log("Unauthorized User");
    }

    // Validation: Check if at least one product is selected
    if (newProduct.product_ids.length === 0) {
      setError("Please select at least one product.");
      return;
    }

    setError(""); // Clear error if validation passes
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/add`,
        newProduct,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      navigate("/school/assigned-product-list");
    } catch (err) {
      console.error(err);
      // Optionally handle error
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "grey.200",
          padding: "10px",
        }}
      >
        <Typography variant="h6">Assign Products</Typography>
        {/* <Button variant="contained" color="primary">
          + Create
        </Button> */}
      </Box>

      <Grid container spacing={1} sx={{ marginTop: "20px", width: "80%" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="#000">
            Select Resource Person
          </Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel>Select Resource Person</InputLabel>
            <Select
              value={newProduct.resource_person}
              onChange={(e) =>
                setNewProduct({
                  ...newProduct,
                  resource_person: e.target.value,
                })
              }
              label="Teacher"
              aria-required="true"
            >
              {resourcePerson.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name ? item.name : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Grid item xs={12} md={6}> */}
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Departments</InputLabel>
            <Select
              value={newProduct.department_id}
              // onChange={(e) =>
              //   setNewProduct({ ...newProduct, department_id: e.target.value })
              // }

              onChange={(e) => {
                setNewProduct({ ...newProduct, department_id: e.target.value });
                setProducts([]); // Uncomment if needed
              }}
              label="Department"
              aria-required="true"
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name
                    ? department.department_name
                    : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
          <p>Selected products</p>
            {alreadySelectedProducts.length > 0 ? 
            (
              alreadySelectedProducts.map((item) => (
                
                <div key={item._id}>
                  {/* {" "} */}
                  {/* Ensure you have a unique key for each item */}
                  {/* <span>Product: {item.product.product_name}</span> */}
      
                  {item.product ? (
          <span>Product: {item.product.product_name}</span>
        ) : (
          <span>Product information not available</span>
        )}
                </div>
              ))
            ) : (
              <p>No products selected earlier</p>
            )}
          </FormControl>

          {/* <FormControl fullWidth margin="normal" variant="outlined">
            {alreadySelectedTeacherData.length > 0 ? (
              alreadySelectedTeacherData.map((item) => (
                <div key={item._id}>
                  {" "}
                 
                  <span>Grade: {item.grade_name?item.grade_name:null}</span>
                  <span>Division: {item.divisions}</span>
                </div>
              ))
            ) : (
              <p></p>
            )}
          </FormControl> */}
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <FormControl component="fieldset" margin="normal" required>
            <Typography>Select Product</Typography>
            {products
                .filter((product) => {
                  // Extract selected division names from alreadySelectedTeacherData
                  const selectedProductNames = alreadySelectedProducts.map(
                    (item) => item.product.product_name // item.divisions is a string
                  );

                  // Show only grades not in selectedProductNames
                  return !selectedProductNames.includes(product.product.product_name); // Assuming grade.division holds the division string
                })
            
            // // products
            .map((product) => (
              <FormControlLabel
                key={product._id}
                control={
                  <Checkbox
                    checked={newProduct.product_ids.includes(product._id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setNewProduct({
                          ...newProduct,
                          product_ids: [...newProduct.product_ids, product._id],
                        });
                      } else {
                        setNewProduct({
                          ...newProduct,
                          product_ids: newProduct.product_ids.filter(
                            (id) => id !== product._id
                          ),
                        });
                      }
                    }}
                  />
                }
                label={
                  product && product.product_name
                    ? product.product_name
                    : "Unnamed Product"
                }
              />
            ))}
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl component="fieldset" margin="normal" required>
            <Typography>Select Product</Typography>
            {products
              // Filter out products that are already selected
              .filter((product) => {
                return !alreadySelectedProducts.some(
                  (selectedProduct) =>
                    selectedProduct.product._id === product._id
                );
              })
              // Map the remaining products for selection
              .map((product) => (
                <FormControlLabel
                  key={product._id}
                  control={
                    <Checkbox
                      checked={newProduct.product_ids.includes(product._id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setNewProduct({
                            ...newProduct,
                            product_ids: [
                              ...newProduct.product_ids,
                              product._id,
                            ],
                          });
                        } else {
                          setNewProduct({
                            ...newProduct,
                            product_ids: newProduct.product_ids.filter(
                              (id) => id !== product._id
                            ),
                          });
                        }
                      }}
                    />
                  }
                  label={
                    product && product.product_name
                      ? product.product_name
                      : "Unnamed Product"
                  }
                />
              ))}
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={3}>
        {error && <Typography color="error">{error}</Typography>}{" "}
        {/* Display error message */}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AssignProduct;
