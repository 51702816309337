import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  TextField,
  TablePagination,
  Button,
  IconButton,
  Switch,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const StudentPage = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalItems, setTotalItems] = useState(0); // Initialize totalItems as number

  useEffect(() => {
    fetchStudents();
  }, [page, rowsPerPage, searchTerm, startDate, endDate]);

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
            start_date: startDate ? new Date(startDate).toISOString() : "",
            end_date: endDate ? new Date(endDate).toISOString() : "",
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data);
        setTotalItems(data.pagination.totalItems); // Correctly set totalItems
      } else {
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  const deleteStudent = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSnackbarMessage("Student deleted successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to delete student");
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      const newStatus = !currentStatus;

      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}/status_student`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSnackbarMessage("Student status updated successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to update student status");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page to 0 when search term changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box padding={3}>
      <Typography variant="h4" component="h1" gutterBottom>
        Students
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              label="Search"
              variant="outlined"
              size="large"
              onChange={handleSearch}
              value={searchTerm}
              sx={{ width: "350px" }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => (
                  <TextField {...params} size="small" />
                )}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                renderInput={(params) => (
                  <TextField {...params} size="small" />
                )}
              />
            </LocalizationProvider>
            <Box flexGrow={1} />
            <Button
              component={Link}
              to="/add-student"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Student
            </Button>
          </Box>
        </Grid>

        <Grid container spacing={3} padding={3}>
          {students.map((student) => (
            <Grid item xs={12} sm={6} md={4} key={student._id}>
              <Card
                sx={{
                  display: 'flex',
                  position: "relative",
                  alignItems: 'normal',
                  padding: 2,
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  transition: 'transform 0.3s ease', // Add smooth hover effect
                  "&:hover": {
                    transform: 'scale(1.05)', // Hover effect
                  },
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <img
                    src={student.profile_url || '/default-profile.png'}
                    alt={`${student.name}'s profile`}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      objectFit: 'cover',

                    }}
                  />

                </Box>

                <Box flexGrow={1} sx={{
                  width: "calc(100% - 50px)"
                }}>
                  <Typography variant="h6" sx={{fontWeight:"700"}} >{student.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {student.email}
                  </Typography>
                  <Grid container spacing={1} mt={0.5} >
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Gender:</strong> {student.gender}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Mobile:</strong> {student.mobile}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Education:</strong> {student.education}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <strong>Status:</strong>
                      <Switch
                        checked={student.status}
                        onChange={() =>
                          handleStatusToggle(student._id, student.status)
                        }
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" gap={0}
                  sx={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}>
                  <IconButton
                    component={Link}
                    to={`/edit-student/${student._id}`}
                    color="primary"
                    sx={{
                      // backgroundColor: '#e0f7fa',
                      '&:hover': { backgroundColor: '#b2ebf2' },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => deleteStudent(student._id)}
                    sx={{
                      // backgroundColor: '#ffebee',
                      '&:hover': { backgroundColor: '#ffcdd2' },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {totalItems > rowsPerPage && (
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[6, 12, 24]}
              labelRowsPerPage="Items per page"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StudentPage;
