import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Box,
  CircularProgress,
  TablePagination,
  Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const ParentsTable = () => {
  const [parents, setParents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusToggle, setStatusToggle] = useState(false);
  const [totalItems, setTotalItems] = useState([]);

  useEffect(() => {
    fetchParents();
  }, [searchTerm, page, rowsPerPage]);

  const fetchParents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/parent?search=${searchTerm}&page=${page + 1}&limit=${rowsPerPage}`);
      const data = await response.json();
      if (data?.data) {
        const parentsWithSerial = data.data.map((parent, index) => ({
          ...parent,
          serial: index + 1
        }));
        setParents(parentsWithSerial);
        setSerialNumbers(parentsWithSerial.map(p => p.serial));
        setTotalItems(data.pagination.totalItems)
        console.log(data.pagination.totalItems);
        
      } else {
        setError('No parents found');
      }
    } catch (err) {
      setError('Failed to fetch parents');
    } finally {
      setLoading(false);
    }
  };

  const deleteParent = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/parent/${id}`, {
        method: 'DELETE',
      });
      setSnackbarMessage('Parent deleted successfully');
      setOpenSnackbar(true);
      fetchParents();
    } catch (err) {
      setError('Failed to delete parent');
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };
  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateParentStatus(id, newStatus); // Update parent status in the backend
  };
  const updateParentStatus = async (id, status) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/parent/${id}/status_parent`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });
      setSnackbarMessage('Parent status updated successfully');
      setOpenSnackbar(true);
      fetchParents(); // Refresh the table after status change
    } catch (err) {
      setError('Failed to update parent status');
    }
  };


  return (
    <div>
      <h2>Parents</h2>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
  
        
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Serial</b></TableCell>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Email</b></TableCell>
              <TableCell><b>Contact Number</b></TableCell>
              <TableCell><b>Relation to Student</b></TableCell>
              <TableCell><b>Status</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parents.length > 0 ? (
              parents.map((parent) => (
                <TableRow key={parent._id}>
                  <TableCell>{parent.serial}</TableCell>
                  <TableCell>{parent.parents_name}</TableCell>
                  <TableCell>{parent.p_email}</TableCell>
                  <TableCell>{parent.contact_num}</TableCell>
                  <TableCell>{parent.relation_to_student}</TableCell>
                  <TableCell>
                  <Switch
                      checked={parent.status}
                      onChange={() => handleStatusToggle(parent._id, parent.status)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => deleteParent(parent._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No parents available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems>10 &&<Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(_, newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
      </Box>}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
         <Alert
           onClose={handleSnackbarClose}
           severity={error? 'error' : 'success'}
         >
           {error || snackbarMessage}
         </Alert>
      </Snackbar>
    </div>
  );
};

export default ParentsTable;
