import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const EditStudentPage = () => {
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState('');
  const [gradess, setGradess] = useState([]);

  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(""); // State for the image preview
  const [form, setForm] = useState({
    name: "",
    grade_id: "",
    division: "",
    mobile: "",
    email: "",
    address: "",
    admission_Date: "",
    gender: "",
    parents_name: "",
    parentEmail: "",
    parentPhone: "",
    relation_to_student: "",
  });

  const DivisionData = [
    { id: 1, name: "A" },
    { id: 2, name: "B" },
    { id: 3, name: "C" },
    { id: 4, name: "D" },
    { id: 5, name: "E" },
    { id: 6, name: "F" },
    { id: 7, name: "G" },
    { id: 8, name: "H" },
    { id: 9, name: "I" },
    { id: 10, name: "J" },
  ];

  useEffect(() => {
    console.log(student);

    const fetchgrades = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade`
        );
        console.log(response.data.data);
        setGradess(response.data.data);
      } catch (error) {
        console.log("Error fetching departments:", error);
      }
    };

    fetchgrades();
  }, []);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchStudent(id);
  }, [id]);

  const fetchStudent = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access this student");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;

      if (data?.data) {
        const studentData = data.data;
        setStudent(studentData);

        // Fetch parent data if parent_id is present
        if (studentData.parent_id) {
          const parentResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/student/gp/${studentData.parent_id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          const parentData = parentResponse.data;

          if (parentData?.data) {
            setForm({
              name: studentData.name || "",
              grade_id: studentData.grade_id || "",
              division: studentData.division || "",
              mobile: studentData.mobile || "",
              email: studentData.email || "",
              address: studentData.address || "",
              profile_url: studentData.profile_url || "",
              admission_Date: studentData.admission_Date
                ? new Date(studentData.admission_Date)
                    .toISOString()
                    .split("T")[0]
                : "",
              gender: studentData.gender || "",
              parents_name: parentData.data.parents_name || "",
              parentEmail: parentData.data.p_email || "",
              parentPhone: parentData.data.contact_num || "",
              relation_to_student: parentData.data.relation_to_student || "",
            });
            // Set initial image preview if the profile URL exists
            if (studentData.profile_url) {
              setProfileImagePreview(studentData.profile_url); // Set the preview from the stored URL
            }
          } else {
            setError("Parent data not found");
          }
        } else {
          setForm({
            name: studentData.name || "",
            grade_id: studentData.grade_id || "",
            division: studentData.division || "",
            mobile: studentData.mobile || "",
            email: studentData.email || "",
            address: studentData.address || "",
            profile_url: studentData.profile_url || "",
            admission_Date: studentData.admission_Date
              ? new Date(studentData.admission_Date).toISOString().split("T")[0]
              : "",
            gender: studentData.gender || "",
            parents_name: "",
            parentEmail: "",
            parentPhone: "",
            relation_to_student: "",
          });
          // Set initial image preview if the profile URL exists
          if (studentData.profile_url) {
            setProfileImagePreview(studentData.profile_url); // Set the preview from the stored URL
          }
        }
      } else {
        setError("Student not found");
      }
    } catch (err) {
      console.error("Error fetching student:", err);
      setError("Failed to fetch student");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setProfileImage(file);
  //     setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
  //   }
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      if (!token) {
        alert("Please login to update student details");
        return;
      }

      const formData = new FormData();
      Object.keys(form).forEach((key) => {
        if (form[key]) formData.append(key, form[key]);
      });

      // If a new image is selected, append the profileImage; otherwise, append the existing profile_url
      if (profileImage) {
        formData.append("profileImage", profileImage); // Append the image only if selected
      } else {
        formData.append("profileImage", form.profile_url); // Retain the current image if no new one is uploaded
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => navigate("/students"), 1000); // Redirect after 2 seconds
      } else {
        throw new Error("Failed to update student");
      }
    } catch (err) {
      console.error("Error updating student:", err);
      setError(err.message || "Failed to update student");
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
        <Typography variant="h4" gutterBottom>
          Add New Student
        </Typography>
        {error && <div>{error}</div>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} item xs={7}>
            <TextField
              label="Name"
              name="name"
              value={form.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel>Grade</InputLabel>
              <Select
                value={form.grade_id}
                onChange={(e) =>
                  setForm({
                    ...form,
                    grade_id: e.target.value,
                  })
                }
                label="Grade"
                aria-required="true"
              >
                {gradess.map((grade) => (
                  <MenuItem key={grade._id} value={grade._id}>
                    {grade.gradename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel>Division</InputLabel>
              <Select
                value={form.division}
                onChange={(e) =>
                  setForm({
                    ...form,
                    division: e.target.value,
                  })
                }
                label="Division"
                aria-required="true"
              >
                {DivisionData.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Mobile"
              name="mobile"
              value={form.mobile}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              value={form.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Address"
              name="address"
              value={form.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Admission Date"
              name="admission_Date"
              type="date"
              value={form.admission_Date}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            {/* <TextField
              label="Gender"
              name="gender"
              value={form.gender}
              onChange={handleChange}
              fullWidth
              margin="normal"
            /> */}

              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Gender</InputLabel>
                <Select
                  value={form.gender}
                  onChange={(e) =>
                    setForm({ ...form, gender: e.target.value })
                  }
                  label="Gender"
                  required
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
          

          
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Profile Image
              </Typography>
              <Button variant="contained" component="label">
                Upload Profile Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>

              {/* Show either the new image preview or the existing profile image */}
              {profileImagePreview && (
                <div style={{ marginTop: "10px" }}>
                  <Typography>Preview:</Typography>
                  <img
                    src={profileImagePreview} // Use profileImagePreview, which is set from either the new image or profile_url
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </Grid>

            <h3>Parent Information</h3>
            <TextField
              label="Parent Name"
              name="parents_name"
              value={form.parents_name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Parent Email"
              name="parentEmail"
              value={form.parentEmail}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Parent Phone"
              name="parentPhone"
              value={form.parentPhone}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            {/* <TextField
              label="Relation to student"
              name="relation_to_student"
              value={form.relation_to_student}
              onChange={handleChange}
              fullWidth
              margin="normal"
            /> */}

<FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Relation to Student</InputLabel>
                <Select
                  value={form.relation_to_student}
                  onChange={handleChange}
                  name="relation_to_student"
                  label="Relation to Student"
                  required
                >
                  <MenuItem value="Father">Father</MenuItem>
                  <MenuItem value="Mother">Mother</MenuItem>
                  <MenuItem value="Guardian">Guardian</MenuItem>
                </Select>
              </FormControl>

            <Button type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
          </Grid>
        </form>
        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success">
            Student updated successfully
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default EditStudentPage;
