
import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import { fetchSchools, addSchool, updateSchool, deleteSchool,softDeleteSchool } from './server/SchoolServices';
const SchoolPage = () => {
    const [schools, setSchools] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [formData, setFormData] = useState({
        school_name: '',
        school_address: '',
        contact_num: '',
        school_mail: '',
        school_website: '',
        established_year: '',
        school_type: ''
    });

    const [error, setError] = useState(null);

    const loadSchools = async () => {
        try {
            const data = await fetchSchools();
            setSchools(data);
        } catch (error) {
            console.error('Error loading schools:', error);
        }
    };
    useEffect(() => {
        loadSchools();
    }, []);

    const handleAddSchool = async () => {
        setShowAddModal(false);

        try {
            await addSchool(formData);

            setFormData({
                school_name: '',
                school_address: '',
                contact_num: '',
                school_mail: '',
                school_website: '',
                established_year: '',
                school_type: ''
            });
            loadSchools()
        } catch (error) {
            console.error('Error adding school:', error);
        }
    };
    const handleUpdateSchool = async () => {
        try {
            await updateSchool(selectedSchool._id, formData);
            setShowUpdateModal(false);
            setSelectedSchool(null);
            setFormData({
                school_name: '',
                school_address: '',
                contact_num: '',
                school_mail: '',
                school_website: '',
                established_year: '',
                school_type: ''
            });
            loadSchools();
        } catch (error) {
            console.error('Error updating school:', error);
        }
    };

    const handleDeleteSchool = async (id) => {
        try {
            await deleteSchool(id);
            loadSchools();
        } catch (error) {
            console.error('Error deleting school:', error);
        }
    };
    const handleSoftDelete = async (id) => {
        try {
            await softDeleteSchool(id); // Call the soft delete API
            // Refresh the list or remove the item from the UI
            loadSchools(); // or however you refresh your list
        } catch (error) {
            console.error('Error soft deleting school:', error);
        }
    };
    
    // Example button for soft delete
        
    const handleShowUpdateModal = (school) => {
        setSelectedSchool(school);
        setFormData(school);
        setShowUpdateModal(true);
    };
    const     handleShowAddModal = (school) => {
        setSelectedSchool(school);
        setFormData(school);
        setShowAddModal(true);
    };


    return (
        <div>
            <h1>Schools</h1>
                <Button variant="primary" onClick={() =>setShowAddModal(true)} className="mb-3">
                Add School
            </Button>

    
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Website</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schools.map((school) => (
                        
                        
                        <tr key={school._id}>
                            <td>{school.school_name}</td>
                            <td>{school.school_address}</td>
                            <td>{school.contact_num}</td>
                            <td>{school.school_mail}</td>
                            <td>{school.school_website}</td>
                            <td>
                                <Button
                                    variant="secondary"
                                    onClick={() => handleShowUpdateModal(school)}
                                >
                                    Update
                                </Button>
                                {' '}
                                <Button
                                    variant="danger"
                                    onClick={() =>handleSoftDelete(school._id)}>

                                
                                    Delete
                                </Button>
                                {/* <button  
                                variant="danger" 
                                onClick={() => 
                                handleSoftDelete(school._id)}>
                                
                                 Delete</button> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add School Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add School</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_name}
                                onChange={(e) => setFormData({ ...formData, school_name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_address}
                                onChange={(e) => setFormData({ ...formData, school_address: e.target.value })}
                            />
                             <Form.Group>
                            <Form.Label>Short Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.short_name}
                                onChange={(e) => setFormData({ ...formData, short_name: e.target.value })}
                            />
                        </Form.Group>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.contact_num}
                                onChange={(e) => setFormData({ ...formData, contact_num: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={formData.school_mail}
                                onChange={(e) => setFormData({ ...formData, school_mail: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_website}
                                onChange={(e) => setFormData({ ...formData, school_website: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Established Year</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.established_year}
                                onChange={(e) => setFormData({ ...formData, established_year: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>School Type</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_type}
                                onChange={(e) => setFormData({ ...formData, school_type: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddSchool} >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Update School Modal */}
            <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update School</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_name}
                                onChange={(e) => setFormData({ ...formData, school_name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_address}
                                onChange={(e) => setFormData({ ...formData, school_address: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.contact_num}
                                onChange={(e) => setFormData({ ...formData, contact_num: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={formData.school_mail}
                                onChange={(e) => setFormData({ ...formData, school_mail: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_website}
                                onChange={(e) => setFormData({ ...formData, school_website: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Established Year</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.established_year}
                                onChange={(e) => setFormData({ ...formData, established_year: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>School Type</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.school_type}
                                onChange={(e) => setFormData({ ...formData, school_type: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdateSchool}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SchoolPage;
