import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
 
const EditDepartment = () => {
  const { id } = useParams();
  console.log("userId", id.department_name);
   // Get the department ID from the URL
  const [formValues, setFormValues] = useState({
    department_id: '',
    department_name: '',
    contact_no: '',
    email_address: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
  // Fetch department details when component mounts or ID changes
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department/${id}`);
        // Ensure the response data matches the formValues structure
        setFormValues({
          department_id: response.data.data.department_id || '',
          department_name: response.data.data.department_name || '',
          contact_no: response.data.data.contact_no || '',
          email_address: response.data.data.email_address || '',
        });
        console.log(response);
       
      } catch (err) {
        console.error('Failed to fetch department details', err);
        setError('Failed to fetch department details');
        setOpenSnackbar(true);
      }
    };
    fetchDepartment();
  }, [id]);
 
  // Handle form input changes
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
 
  // Handle form submission
  const handleSubmit = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${id}`, formValues);
      setSnackbarMessage('Department updated successfully');
      setOpenSnackbar(true);
      navigate('/department_list'); // Redirect to the main page after successful update
    } catch (err) {
      console.error('Failed to update department', err);
      setError('Failed to update department');
      setOpenSnackbar(true);
    }
  };
 
  return (
    <Dialog open={true} onClose={() => navigate('/department_list')}>
      <DialogTitle>Edit Department</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Department ID"
          fullWidth
          name="department_id"
          value={formValues.department_id}
          onChange={handleChange}
          disabled // Disable this field if it should not be edited
        />
        <TextField
          margin="dense"
          label="Department Name"
          fullWidth
          name="department_name"
          value={formValues.department_name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Contact No"
          fullWidth
          name="contact_no"
          value={formValues.contact_no}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Email Address"
          fullWidth
          name="email_address"
          value={formValues.email_address}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate('/department_list')}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
 
      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
 
export default EditDepartment;