import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const EditAssignedCourse = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [newTeacher, setNewTeacher] = useState({
    teacherCategory: "",
    teacherSubCategory: "",
    teacher: "",
    // department: "",
    grade_id: "", // Initialize grade_id state
    grade_division_id: [],
    course_id: "",
  });

  const [error, setError] = useState(""); // State for error handling
  const [loading, setLoading] = useState(true);
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedGradeDivisions, setSelectedGradeDivisions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    fetchAssignCourse(id);
  }, [id]);

  // Fetch assigned course details by ID
  const fetchAssignCourse = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/byId/${id}`
      );
      const data = response.data;

      if (data?.data) {
        const courseData = data.data;
        console.log(courseData);

        setNewTeacher({
          teacherCategory: courseData.teacher_category_id,
          teacherSubCategory: courseData.teacher_sub_category_id,
          teacher: courseData.teacher_id,
          grade_id: courseData.grade_id,
          grade_division_id: courseData.grade_division_id,
          course_id: courseData.course_id,
        });

   
        
      } else {
        setError("Assigned course not found");
      }
    } catch (err) {
      console.error("Error fetching assigned course:", err);
      setError("Failed to fetch assigned course");
    } finally {
      setLoading(false);
    }
  };

  // Fetch teacher categories
  useEffect(() => {
    const fetchTeacherCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teachercategory`
        );
        setTeacherCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchTeacherCategories();
  }, []);

  

  // Fetch teacher sub-categories
  useEffect(() => {
    const fetchTeacherSubCategories = async () => {
      if (!newTeacher.teacherCategory) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacher/sub/${newTeacher.teacherCategory}`
        );
        setTeacherSubCategories(response.data.data);
        setFilteredSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher sub-categories:", error);
      }
    };
    fetchTeacherSubCategories();
  }, [newTeacher.teacherCategory]);

  // Filter sub-categories based on selected teacher category
  useEffect(() => {
    if (newTeacher.teacherCategory) {
      setFilteredSubcategories(
        teacherSubCategories.filter(
          (sub) => sub.teacher_category_id === newTeacher.teacherCategory
        )
      );
    }
  }, [teacherSubCategories, newTeacher.teacherCategory]);

  // Fetch teachers based on sub-category
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacher`
        );
        const teacherData = response.data.data;
        const filteredTeachers = teacherData.filter(
          (item) => item.teacher_subCategoryId === newTeacher.teacherSubCategory
        );
        setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchTeachers();
  }, [newTeacher.teacherSubCategory]);

  
  useEffect(() => {
    const fetchAllgrades = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/grade`
      );

      const data = await response.json();
      if (response.ok) {
        setAllGrades(data.data); // Set grades from the response
      }
    };
    fetchAllgrades();
  }, []);
  // Fetch grades based on selected department
  useEffect(() => {
    const fetchGrades = async () => {
      if (!newTeacher.grade_id) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/class/grade/${newTeacher.grade_id}`
        );
        const data = await response.json();
        if (response.ok) {
          setGrades(data.data);
          console.log("Courses related gradeId: ", data.data);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchGrades();
  }, [newTeacher.grade_id]);

  // Fetch courses based on selected department
  useEffect(() => {
    const fetchCourses = async () => {
      if (!newTeacher.grade_id) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/${newTeacher.grade_id}`
        );
        setCourses(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [newTeacher.grade_id]);

  // Handle course selection
  const handleCourseChange = (courseId) => {
    setNewTeacher((prevState) => {
      const selectedCourses = prevState.courses.includes(courseId)
        ? prevState.courses.filter((id) => id !== courseId) // Remove course if already selected
        : [...prevState.courses, courseId]; // Add course if not selected
      return { ...prevState, courses: selectedCourses };
    });
  };

  // Handle class selection
  const handleClassChange = (gradeId, division) => {
    setNewTeacher((prevState) => {
      const currentGrades = [...prevState.grades];
      const index = currentGrades.findIndex((grade) => grade.id === gradeId);
      if (index > -1) {
        // If already selected, remove it
        currentGrades.splice(index, 1);
      } else {
        // Add if not selected
        currentGrades.push({ id: gradeId, division });
      }
      return { ...prevState, grades: currentGrades };
    });
  };

  // Handle division checkbox change
  const handleDivisionChange = (divisionId) => {
    setNewTeacher((prevState) => {
      const isSelected = prevState.grade_division_id.includes(divisionId);
      return {
        ...prevState,
        grade_division_id: isSelected
          ? prevState.grade_division_id.filter((id) => id !== divisionId)
          : [...prevState.grade_division_id, divisionId],
      };
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (
      Object.keys(newTeacher.grade_id).length === 0 ||
      Object.values(newTeacher.grade_id).every((div) => div.length === 0)
    ) {
      setError("Please select at least one grade and division.");
      return; // Prevent submission
    }

    setError(""); 
    

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/edit/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTeacher),
        }
      );
      if (response.ok) {
        setSuccess(true);
        setTimeout(() => navigate("/school/assigned-courses-list"), 2000); // Redirect after 2 seconds
      } else {
        setError("Failed to update teacher");
      }
    } catch (err) {
      setError("Failed to update teacher");
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "grey.200",
          padding: "10px",
        }}
      >
        <Typography variant="h6">Assign Courses</Typography>
        {/* <Button variant="contained" color="primary">
          + Create
        </Button> */}
      </Box>

      <Grid container spacing={1} sx={{ marginTop: "20px", width: "80%" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="#000">
            Select Teacher
          </Typography>

          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Teacher Category</InputLabel>
            <Select
              value={newTeacher.teacherCategory}
              onChange={(e) =>
                setNewTeacher({
                  ...newTeacher,
                  teacherCategory: e.target.value,
                })
              }
              label="Teacher Category"
              aria-required="true"
            >
              {teacherCategories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.teacher_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Teacher Sub Category</InputLabel>
            <Select
              value={newTeacher.teacherSubCategory}
              onChange={(e) =>
                setNewTeacher({
                  ...newTeacher,
                  teacherSubCategory: e.target.value,
                })
              }
              label="Teacher Sub Category"
              aria-required="true"
              disabled={!newTeacher.teacherCategory}
            >
              {filteredSubcategories.map((subCategory) => (
                <MenuItem key={subCategory._id} value={subCategory._id}>
                  {subCategory.teacher_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Select Teacher</InputLabel>
            <Select
              value={newTeacher.teacher}
              onChange={(e) =>
                setNewTeacher({ ...newTeacher, teacher: e.target.value })
              }
              label="Teacher"
              aria-required="true"
              disabled={!newTeacher.teacherSubCategory}
            >
              {teacherFilter.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Department</InputLabel>
            <Select
              value={newTeacher.department}
              onChange={(e) => setNewTeacher({ ...newTeacher, department: e.target.value })}
              label="Department"
              aria-required="true"
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

          {/* <Grid item xs={12} md={6}> */}
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Grades</InputLabel>
            <Select
              value={newTeacher.grade_id}
              onChange={(e) =>
                setNewTeacher({ ...newTeacher, grade_id: e.target.value })
              }
              label="Department"
              aria-required="true"
            >
              {allGrades.map((grade) => (
                <MenuItem key={grade._id} value={grade._id}>
                  {grade.gradename}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
            variant="outlined"
            required
            InputLabelProps={{ required: false }}
          >
            <InputLabel className="mb-4" shrink={true}>
              Divisions {/* Manually add the asterisk here if required */}
            </InputLabel>
            <FormGroup row className="ms-3 mt-2">
              {" "}
              {/* Add 'row' to make checkboxes horizontal */}
              {grades.map((grade) => (
                <FormControlLabel
                  key={grade._id}
                  control={
                    <Checkbox
                      checked={newTeacher.grade_division_id.includes(grade._id)} // Check if this division is selected
                      onChange={() => handleDivisionChange(grade._id)}
                      name={grade.division} // Name for accessibility and identification
                      color="primary" // Optional: to customize checkbox color
                    />
                  }
                  label={grade.division} // Display the division name
                  style={{ marginRight: "16px" }} // Add spacing between checkboxes
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        {/* <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Select Course</InputLabel>
            <Select
              value={newTeacher.courses}
              onChange={(e) => setNewTeacher({ ...newTeacher, courses : e.target.value })}
              label="Department"
              aria-required="true"
            >
              {courses.map((course) => (
                <MenuItem key={course._id} value={course._id}>
                  Grade {course.standard} {course.division}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        <Grid item xs={12} md={6}>
          <FormControl component="fieldset" margin="normal" required>
            <Typography>Select Course</Typography>
            <RadioGroup
              value={newTeacher.course_id}
              onChange={(e) =>
                setNewTeacher({ ...newTeacher, course_id: e.target.value })
              }
            >
              {courses.map((course) => (
                <FormControlLabel
                  key={course._id}
                  value={course._id}
                  control={<Radio />}
                  label={`${course.courses_name}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={3}>
        {error && <Typography color="error">{error}</Typography>}{" "}
        {/* Display error message */}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default EditAssignedCourse;
