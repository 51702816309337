import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  TextareaAutosize,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill styles

const steps = [
  "Project Info",
  "Introduction",
  "Page 3",
  "Page 4",
  "Page 5",
  "Page 6",
  "Page 7",
  "Page 8",
  "Thank You",
];

const MultiStepForm = () => {
  // Step state to manage the current step of the form
  const [step, setStep] = useState(1);

  // Form data state
  const [formData, setFormData] = useState({
    projectName: "",
    teamName: "",
    reason: "",
    introduction: "",
    pagesContent: [],
    tableContent: [],
    textAreas: ["", ""],
    thankYou: "",
    dynamicRows: [],
  });

  const navigate = useNavigate();

  // Handle moving to the next and previous steps
  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handlePrev = () => setStep((prevStep) => prevStep - 1);

  // Handle changes in input fields
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle changes in Quill editor
  const handleQuillChange = (content, id) => {
    setFormData({ ...formData, [id]: content });
  };

  // Handle table row input changes
  const handleInputChangeInTable = (e, index, column) => {
    const { value } = e.target;
    const updatedRows = [...formData.dynamicRows];
    updatedRows[index][column] = value;
    setFormData({ ...formData, dynamicRows: updatedRows });
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log("Form submitted", formData);
  };

  // View the project (navigate to the project view page)
  const viewProject = () => {
    navigate("/projectview/:id");
  };

  // Handle adding a new row in the table
  const handleAddRow = () => {
    setFormData({
      ...formData,
      dynamicRows: [...formData.dynamicRows, { column1: "", column2: "" }],
    });
  };

  return (
    <Box
      sx={{
        padding: "30px",
        maxWidth: "80%",
        margin: "0 auto",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
      }}
    >
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Create a Project
      </Typography>

      {/* Stepper for navigation between steps */}
      <Stepper activeStep={step - 1} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Render different components based on the current step */}
      {step === 1 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Step 1: Project Info
          </Typography>
          <TextField
            label="Project Name"
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Team Name"
            name="teamName"
            value={formData.teamName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Box>
      )}

      {step === 2 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Step 2: Introduction
          </Typography>
          <TextField
            label="Project Name"
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
            Reason for creating the project:
          </Typography>
          <ReactQuill
            value={formData.reason}
            onChange={(content) => handleQuillChange(content, "reason")}
          />
        </Box>
      )}

      {step >= 3 && step <= 7 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Step 5: Table Form
          </Typography>
          {/* <Typography variant="h6" sx={{ mb: 2 }}>
            Step {step}: Page {step}
          </Typography> */}
          <TextField
            label={`Page ${step} Title`}
            name={`titlePage${step}`}
            value={formData[`titlePage${step}`] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          {/* <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
            Content for Page {step}:
          </Typography>
          <ReactQuill
            value={formData.pagesContent[step - 3] || ""}
            onChange={(content) =>
              handleQuillChange(content, `pagesContent[${step - 3}]`)
            }
          /> */}
        </Box>
      )}

      {step === 5 && (
        <Box>
          <TextField
            label="Table Title"
            name="tableTitle"
            value={formData.tableTitle || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <table>
            <thead>
              <tr>
                <th>Column 1</th>
                <th>Column 2</th>
              </tr>
            </thead>
            <tbody>
              {formData.dynamicRows.map((row, index) => (
                <tr key={index}>
                  <td>
                    <TextField
                      value={row.column1}
                      onChange={(e) =>
                        handleInputChangeInTable(e, index, "column1")
                      }
                    />
                  </td>
                  <td>
                    <TextField
                      value={row.column2}
                      onChange={(e) =>
                        handleInputChangeInTable(e, index, "column2")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button onClick={handleAddRow}>Add Row</Button>
        </Box>
      )}

      {step === 8 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Step 8: Additional Information
          </Typography>
          <TextareaAutosize
            placeholder="First Text Area"
            name="textArea1"
            value={formData.textAreas[0]}
            onChange={(e) =>
              setFormData({
                ...formData,
                textAreas: [e.target.value, formData.textAreas[1]],
              })
            }
            style={{
              width: "100%",
              height: 100,
              margin: "10px 0",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <TextareaAutosize
            placeholder="Second Text Area"
            name="textArea2"
            value={formData.textAreas[1]}
            onChange={(e) =>
              setFormData({
                ...formData,
                textAreas: [formData.textAreas[0], e.target.value],
              })
            }
            style={{
              width: "100%",
              height: 100,
              margin: "10px 0",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </Box>
      )}

      {step === 9 && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Step 9: Thank You Page
          </Typography>
          <ReactQuill
            value={formData.thankYou}
            onChange={(content) => handleQuillChange(content, "thankYou")}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="outlined" color="primary" onClick={viewProject}>
              View Project
            </Button>
          </Box>
        </Box>
      )}

      {/* Navigation buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button
          disabled={step === 1}
          onClick={handlePrev}
          variant="outlined"
          color="primary"
        >
          Previous
        </Button>
        <Button
          disabled={step === 9}
          onClick={handleNext}
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default MultiStepForm;
