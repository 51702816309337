import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Updated sample data for products
const data = [
  {
    id: 1,
    productCategory: "Modi Master Kit - MQ0087/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Excellent",
  },
  {
    id: 2,
    productCategory: "Modi Master Kit - MQ0088/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Good",
  },
  {
    id: 3,
    productCategory: "Modi Master Kit - MQ0089/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Excellent",
  },
  {
    id: 4,
    productCategory: "Modi Master Kit - MQ0090/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Excellent",
  },
  {
    id: 5,
    productCategory: "Modi Master Kit - MQ0091/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Average",
  },
  {
    id: 6,
    productCategory: "Modi Master Kit - MQ0092/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Excellent",
  },
  {
    id: 7,
    productCategory: "Modi Master Kit - MQ0093/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Poor",
  },
  {
    id: 8,
    productCategory: "Modi Master Kit - MQ0094/23",
    qtyIssued: "1 Nos",
    availableQty: "1 Nos",
    condition: "Damaged",
  },
];

// Top section buttons data
const topButtons = [
  { label: "44 Products", color: "#e4443f" },
  { label: "0 Missing", color: "#e4443f" },
  { label: "1 Damaged", color: "#e4443f" },
];
const ProductAssigned = () => {
  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#000",
            width: "20%",
            fontWeight: "bold",
            backgroundColor: "#e4e1de",
            padding: "6px",
            textAlign: "center",
          }}
        >
          PRODUCT ASSIGNED TO{" "}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#000",
            width: "50%",
            fontWeight: "bold",
            backgroundColor: "#e4e1de",
            padding: "6px",
          }}
        >
          SHIVANI GUPTA
        </Typography>

        {/* Top Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          {topButtons.map((button, index) => (
            <Box
              key={index}
              variant="contained"
              sx={{
                backgroundColor: "#e4443f",
                color: "#fff",
                fontSize: "17px",
                // fontWeight: "bold",
                "&:hover": { backgroundColor: button.color, opacity: 1 },
                cursor: "default",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "8px",
                paddingBottom: "8px",
                width: "130px",
                textAlign: "center",
              }}
            >
              {button.label}
            </Box>
          ))}
        </Box>
      </Box>

      <Grid container spacing={3} marginBottom={2}>
        {/* Top Row - 2 Selects */}
        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Grade</MenuItem>
            {/* Add more grade options */}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Division</MenuItem>
            {/* Add more division options */}
          </Select>
        </Grid>

        {/* Empty space to center the 2 selectors in the first row */}
        <Grid item xs={4} />

        {/* Bottom Row - 3 Selects */}
        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">All product Category</MenuItem>
            {/* Add more course options */}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">All Product Sub Category</MenuItem>
            {/* Add more chapter options */}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Unit</MenuItem>
            {/* Add more unit options */}
          </Select>
        </Grid>
      </Grid>

      {/* Student Table */}
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ width: "70% " }}>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e5cba4",
                  fontWeight: "bold",
                  padding: "12px",
                  width: "50%",
                  borderRight: "2px solid white",
                }}
              >
                Product Category
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e5cba4",
                  fontWeight: "bold",
                  padding: "12px",
                  width: "8%",
                  borderRight: "2px solid white",
                }}
              >
                Qty Issued
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e5cba4",
                  fontWeight: "bold",
                  padding: "12px",
                  width: "8%",
                  borderRight: "2px solid white",
                }}
              >
                Avilable Qty
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e5cba4",
                  fontWeight: "bold",
                  padding: "12px",
                  width: "8%",
                  borderRight: "2px solid white",
                }}
              >
                Condition
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          {/* Table Body */}
          <TableBody>
            {data.map((product) => (
              <TableRow key={product.id}>
                {/* Product Category */}
                <TableCell
                  sx={{
                    padding: "12px", // Add padding to match the layout
                    borderBottom: "none", // Remove bottom borders to match the look
                  }}
                >
                  <TextField
                    value={product.productCategory}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "100%", // Full width for the TextField
                      "& .MuiInputBase-input": {
                        textAlign: "left", // Align text to left
                        padding: "10px 8px", // Adjust padding to match input size
                      },
                    }}
                  />
                </TableCell>

                {/* Quantity Issued */}
                <TableCell
                  sx={{
                    padding: "12px", // Ensure equal padding for the cells
                    borderBottom: "none", // Match the provided image",
                  }}
                >
                  <TextField
                    value={product.qtyIssued}
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        padding: "10px 8px", // Adjust padding to match input size
                      },
                      width: "100px",
                      height: "40px", // Adjust height of the text field to match the provided image
                    }}
                  />
                </TableCell>

                {/* Available Quantity */}
                <TableCell
                  sx={{
                    padding: "12px", // Ensure equal padding for the cells
                    borderBottom: "none", // Match the provided image
                  }}
                >
                  <TextField
                    value={product.availableQty}
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        padding: "10px 8px", // Adjust padding to match input size
                      },
                      width: "100px",
                      height: "40px", // Adjust height of the text field to match the provided image
                    }}
                  />
                </TableCell>

                {/* Condition */}
                <TableCell
                  sx={{
                    padding: "12px", // Ensure equal padding for the cells
                    borderBottom: "none", // Match the provided image
                  }}
                >
                  <TextField
                    value={product.condition}
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        padding: "10px 8px", // Adjust padding to match input size
                      },
                      width: "100px",
                      height: "40px", // Adjust height of the text field to match the provided image
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductAssigned;
