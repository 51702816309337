import React, { useState } from "react";
import { Button, Box, Grid, Typography, LinearProgress } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom"; // UseNavigate from react-router-dom

const courses = [
  {
    id: 1,
    grade: "Grade 3",
    title: "Harnessing Renewable Energy",
    progress: 63,
  },
  {
    id: 2,
    grade: "Grade 5",
    title: "Harnessing Renewable Energy",
    progress: 63,
  },
  {
    id: 3,
    grade: "Grade 6",
    title: "Harnessing Renewable Energy",
    progress: 63,
  },
  {
    id: 4,
    grade: "Grade 7",
    title: "Harnessing Renewable Energy",
    progress: 63,
  },
  {
    id: 5,
    grade: "Grade 8",
    title: "Harnessing Renewable Energy",
    progress: 63,
  },
  {
    id: 6,
    grade: "Grade 10",
    title: "Harnessing Renewable Energy",
    progress: 63,
  },
];

function Grade() {
  const [view, setView] = useState("ongoing");
  const navigate = useNavigate(); // UseNavigate instead of useHistory

  // Handle redirection when arrow icon is clicked
  const handleRedirect = (courseId) => {
    navigate(`/gradeview`); // Use navigate instead of history.push
  };

  return (
    <Box p={2} sx={{ width: "70%" }}>
      {/* Buttons */}
      <Box display="flex" justifyContent="start" mb={3}>
        <Button
          variant="contained"
          onClick={() => setView("ongoing")}
          startIcon={<ArrowForwardIcon />}
          style={{
            marginRight: "10px",
            backgroundColor: view === "ongoing" ? "black" : "orange",
            color: view === "ongoing" ? "white" : "black",
            borderRadius: "5px", // Border radius for buttons
            fontWeight: "bold", // Make font bold
            fontSize: "15px", // Increased font size by 1px
          }}
        >
          Ongoing Courses
        </Button>
        <Button
          variant="contained"
          onClick={() => setView("completed")}
          startIcon={<ArrowForwardIcon />}
          style={{
            backgroundColor: view === "completed" ? "black" : "orange",
            color: view === "completed" ? "white" : "black",
            borderRadius: "5px", // Border radius for buttons
            fontWeight: "bold", // Make font bold
            fontSize: "15px", // Increased font size by 1px
          }}
        >
          Completed Courses
        </Button>
      </Box>

      {/* Courses List */}
      <Grid container spacing={0}>
        {courses.map((course) => (
          <Grid item xs={12} key={course.id}>
            <Box display="flex" alignItems="center" mb={1}>
              {/* Course Number */}
              <Box
                bgcolor="black"
                color="white"
                p={1}
                width={40}
                textAlign="center"
                mr={2}
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }} // Bold and increased font size
              >
                {course.id}
              </Box>
              {/* Grade and Title */}
              <Box
                bgcolor="#e4443f"
                color="white"
                p={1}
                textAlign="center"
                width={100}
                mr={2}
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "19px",
                }} // Bold and increased font size
              >
                {course.grade}
              </Box>
              <Box
                flexGrow={1}
                bgcolor="#fde0a9"
                p={1}
                textAlign="left"
                mr={2}
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }} // Bold and increased font size
              >
                {course.title}
              </Box>
              {/* Clickable Icon for Redirection */}
              <Box ml={2}>
                <Box
                  bgcolor="#e4443f"
                  p={1}
                  borderRadius="0%" // Circular icon button
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => handleRedirect(course.id)}
                  style={{ cursor: "pointer" }} // Clickable arrow for redirection
                >
                  <ArrowForwardIcon style={{ color: "white" }} />
                </Box>
              </Box>
            </Box>
            {/* Progress Bar */}
            <Box mt={1} mb={2} ml={22} mr={10}>
              {" "}
              {/* Added margin-left to position the progress bar under the course row */}
              <LinearProgress
                variant="determinate"
                value={course.progress}
                style={{ height: "5px", borderRadius: "0px" }} // Smaller, rounded progress bar
              />
              <Typography
                mt={1}
                textAlign="right"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                {course.progress}%
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Grade;
