import React, { useEffect, useState } from 'react';
import { TextField, Button, CircularProgress, Snackbar, Alert, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../../utils/AuthUtils';

const EditStudentPage = () => {
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(""); // State for the image preview
  const [form, setForm] = useState({
    name: '',
    education: '',
    mobile: '',
    email: '',
    address: '',
    admission_Date: '',
    gender: '',
    parents_name: '',
    parentEmail: '',
    parentPhone: '',
    relation_to_student: ''
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchStudent(id);
  }, [id]);

  const fetchStudent = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access this student");
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/student/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const data = response.data;

      if (data?.data) {
        const studentData = data.data;
        setStudent(studentData);

        // Fetch parent data if parent_id is present
        if (studentData.parent_id) {
          const parentResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/student/gp/${studentData.parent_id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          const parentData = parentResponse.data;

          if (parentData?.data) {
            setForm({
              name: studentData.name || '',
              education: studentData.education || '',
              mobile: studentData.mobile || '',
              email: studentData.email || '',
              address: studentData.address || '',
              profileImage: studentData.profileImage || '',
              admission_Date: studentData.admission_Date ? new Date(studentData.admission_Date).toISOString().split('T')[0] : '',
              gender: studentData.gender || '',
              parents_name: parentData.data.parents_name || '',
              parentEmail: parentData.data.p_email || '',
              parentPhone: parentData.data.contact_num || '',
              relation_to_student: parentData.data.relation_to_student || ''
            });
          } else {
            setError('Parent data not found');
          }
        } else {
          setForm({
            name: studentData.name || '',
            education: studentData.education || '',
            mobile: studentData.mobile || '',
            email: studentData.email || '',
            address: studentData.address || '',
            admission_Date: studentData.admission_Date ? new Date(studentData.admission_Date).toISOString().split('T')[0] : '',
            gender: studentData.gender || '',
            parents_name: '',
            parentEmail: '',
            parentPhone: '',
            relation_to_student: ''
          });
        }
      } else {
        setError('Student not found');
      }
    } catch (err) {
      console.error('Error fetching student:', err);
      setError('Failed to fetch student');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      if (!token) {
        alert('Please login to update student details');
        return;
      }

      const formData = new FormData();
      Object.keys(form).forEach(key => {
        if (form[key]) formData.append(key, form[key]);
      });
      if (profileImage) {
        formData.append('profileImage', profileImage); // Append the image only if selected
      }

      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/student/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => navigate('/students'), 2000); // Redirect after 2 seconds
      } else {
        throw new Error('Failed to update student');
      }
    } catch (err) {
      console.error('Error updating student:', err);
      setError(err.message || 'Failed to update student');
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <h2>Edit Student</h2>
      {error && <div>{error}</div>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={form.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Education"
          name="education"
          value={form.education}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Mobile"
          name="mobile"
          value={form.mobile}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={form.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          name="address"
          value={form.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Admission Date"
          name="admission_Date"
          type="date"
          value={form.admission_Date}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Gender"
          name="gender"
          value={form.gender}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Profile Image
          </Typography>
          <Button variant="contained" component="label">
            Upload Profile Image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {profileImage && (
            <div>
              <Typography>{profileImage.name}</Typography>
              <img
                src={profileImagePreview}
                alt="Profile Preview"
                style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
              />
            </div>
          )}
        </Grid>

        <h3>Parent Information</h3>
        <TextField
          label="Parent Name"
          name="parents_name"
          value={form.parents_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Parent Email"
          name="parentEmail"
          value={form.parentEmail}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Parent Phone"
          name="parentPhone"
          value={form.parentPhone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Relation to student"
          name="relation_to_student"
          value={form.relation_to_student}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </form>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          Student updated successfully
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditStudentPage;
