import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Grid,
} from "@mui/material";

// Sample data for students and scores
const data = [
  { id: 1, name: "Student 1", score: "59 / 100" },
  { id: 2, name: "Student 2", score: "64 / 100" },
  { id: 3, name: "Student 3", score: "64 / 100" },
  { id: 4, name: "Student 4", score: "64 / 100" },
  { id: 5, name: "Student 5", score: "64 / 100" },
  { id: 6, name: "Student 6", score: "64 / 100" },
  { id: 7, name: "Student 7", score: "64 / 100" },
];
// Top section buttons data
const topButtons = [
  { label: "7 Grades", color: "#e4443f" },
  { label: "450 Students", color: "#e4443f" },
  { label: "10 Courses", color: "#e4443f" },
];
const UnitAssessment = () => {
  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#000",
            width: "73%",
            fontWeight: "bold",
            backgroundColor: "#e4e1de",
            padding: "6px",
          }}
        >
          UNIT ASSESSMENT - Division Wise
        </Typography>

        {/* Top Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          {topButtons.map((button, index) => (
            <Box
              key={index}
              variant="contained"
              sx={{
                backgroundColor: "#e4443f",
                color: "#fff",
                fontWeight: "bold",
                "&:hover": { backgroundColor: button.color, opacity: 1 },
                cursor: "default",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                width: "130px",
                textAlign: "center",
              }}
            >
              {button.label}
            </Box>
          ))}
        </Box>
      </Box>

      <Grid container spacing={3} marginBottom={2}>
        {/* Top Row - 2 Selects */}
        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Grade</MenuItem>
            {/* Add more grade options */}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Division</MenuItem>
            {/* Add more division options */}
          </Select>
        </Grid>

        {/* Empty space to center the 2 selectors in the first row */}
        <Grid item xs={4} />

        {/* Bottom Row - 3 Selects */}
        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Course</MenuItem>
            {/* Add more course options */}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Chapter</MenuItem>
            {/* Add more chapter options */}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Unit</MenuItem>
            {/* Add more unit options */}
          </Select>
        </Grid>
      </Grid>

      {/* Student Table */}
      <TableContainer  sx={{ marginTop: 2 }}>
        <Table sx={{ width: "50% " }}>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e5cba4",
                  fontWeight: "bold",
                  padding: "12px",
                  width: "100%", // 50% width for student name column
                  borderRight: "2px solid white", // Adds space between headers
                }}
              >
                Student Name
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e5cba4",
                  fontWeight: "bold",
                  padding: "12px",
                }}
              >
                Score
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {data.map((student) => (
              <TableRow key={student.id}>
                {/* Student Name in a Box */}
                <TableCell
                  sx={{
                    padding: "12px", // Add padding to match the layout
                    borderBottom: "none", // Remove bottom borders to match the look
                  }}
                >
                  <TextField
                    value={student.name}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "100%", // Full width for the TextField
                      "& .MuiInputBase-input": {
                        textAlign: "left", // Align text to left
                        padding: "10px 8px", // Adjust padding to match input size
                      },
                    }}
                  />
                </TableCell>

                {/* Student Score */}
                <TableCell
                  sx={{
                    padding: "12px", // Ensure equal padding for the cells
                    borderBottom: "none", // Match the provided image
                  }}
                >
                  <TextField
                    value={student.score}
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        padding: "10px 8px", // Adjust padding to match input size
                      },
                      width: "100px",
                      height: "40px", // Adjust height of the text field to match the provided image
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UnitAssessment;
