import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
  CircularProgress, Container, Box, IconButton, Switch, TextField, MenuItem,
  TablePagination,
  Snackbar,
  Alert
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import { Grid } from '@mui/system';
import { useNavigate } from 'react-router-dom';
 
const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [departmentCategorySubs, setDepartmentCategorySubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState([])
  
 
  useEffect(() => {
    fetchDepartments();
    fetchDepartmentCategories();
    fetchDepartmentCategorySubs();
  }, [page, rowsPerPage, searchTerm]);
 
  // Fetch Departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department?page=${page + 1}&limit=${rowsPerPage}`);
      console.log(response.data); // Log the data to check its structure
      if (Array.isArray(response.data.data)) {
        setDepartments(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } else {
        console.error('Expected an array but received:', response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching departments", error);
      setError(error);
      setLoading(false);
    }
  };
 
  // Fetch Department Categories
  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category`);
      setDepartmentCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };
 
  // Fetch Department Category Subs
  const fetchDepartmentCategorySubs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category_sub`);
      setDepartmentCategorySubs(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department category subs", error);
    }
  };
 
  // Handle Save or Update
  const handleSave = async () => {
    try {
      if (isEdit) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${currentDepartment._id}`, currentDepartment);
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/department`, currentDepartment);
      }
      fetchDepartments();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving department", error);
    }
  };
 
  // Delete Department
  const handleDelete = async () => {
    if (departmentToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/department/${departmentToDelete._id}`);
        fetchDepartments();
        handleCloseConfirmDialog();
      } catch (error) {
        console.error("Error deleting department", error);
      }
    }
  };

  const handleNavigate = () => {
    navigate('/school/assign-department');
  }
 
  // Toggle Status
  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${id}`, { status: status === 1 ? 0 : 1 });
      fetchDepartments();
      
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };
 
  // Open Dialog for Add/Edit
  const handleOpenDialog = (department) => {
    setCurrentDepartment(department || {});
    setIsEdit(!!department);
    setOpenDialog(true);
  };
 
  // Close Dialog
  const handleCloseDialog = () => {
    setCurrentDepartment({});
    setOpenDialog(false);
  };
 
  // Open Confirmation Dialog
  const handleOpenConfirmDialog = (department) => {
    setDepartmentToDelete(department);
    setConfirmDelete(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("newPage",newPage);
    
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };
 
  // Close Confirmation Dialog
  const handleCloseConfirmDialog = () => {
    setDepartmentToDelete(null);
    setConfirmDelete(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
 
  if (loading) return <CircularProgress />;
 
  if (error) return <Typography color="error">Error loading data: {error.message}</Typography>;
 
  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" gutterBottom>
          Department List department
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/school/assign-department')}>
          Assign Department
        </Button>
      </Box>

      {/* <TextField
        label="Search"
        variant="outlined"
        onChange={handleSearch}
        style={{ marginBottom: '20px' }}
      /> */}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell>HOD</TableCell>
              <TableCell>Contact No</TableCell>
              <TableCell>WhatsApp No</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>No of Labs</TableCell>
              <TableCell>Lab Location</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department, index) => (
              <TableRow key={department._id}>
                <TableCell>{index + 1 + (page * rowsPerPage)}</TableCell>
                <TableCell>{department.department_name}</TableCell>
                {/* <TableCell>{department.department_hod}</TableCell>
                <TableCell>{department.contact_no}</TableCell>
                <TableCell>{department.whatsapp_no}</TableCell>
                <TableCell>{department.email_address}</TableCell>
                <TableCell>{department.no_of_labs}</TableCell>
                <TableCell>{department.lab_location}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems>rowsPerPage &&<Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
      </Box>}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};
 
export default DepartmentList;