import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Box,
  TablePagination,
  Switch,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ChaptersTable = () => {
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState([])

  useEffect(() => {
    fetchChapters();
  }, [currentPage, pageSize, searchTerm]);

  const fetchChapters = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `http://localhost:8080/api/chapters?page=${currentPage + 1}&limit=${pageSize}&search=${searchTerm}`
      );
      const data = await response.json();
      if (data?.data) {
        setChapters(data.data);
        console.log(data.data);
        setTotalItems(data.pagination.totalItems)
        setError(null);
      } else {
        setError("No chapters found");
      }
    } catch (err) {
      setError("Failed to fetch chapters");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateChapterStatus(id, newStatus);
  };

  const updateChapterStatus = async (id, status) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/chapters/${id}/status_chapter`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      });
      setSnackbarMessage("Chapter status updated successfully");
      setOpenSnackbar(true);
      fetchChapters();
    } catch (err) {
      setError("Failed to update chapter status");
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <h2>Chapters</h2>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          component={Link}
          to="/chapters"  // Redirects to AddChapter form
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Chapter
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Serial</b></TableCell>
              <TableCell><b>Standard</b></TableCell>
              <TableCell><b>Course</b></TableCell>
              <TableCell><b>Unit Name</b></TableCell>
              <TableCell><b>Chapter Name</b></TableCell>
              <TableCell><b>Status</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : chapters.length > 0 ? (
              chapters.map((chapter, index) => (
                <TableRow key={chapter._id}>
                  <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                  <TableCell>{chapter.class_id?.standard || 'N/A'}</TableCell> {/* Displaying 'standard' */}
                  <TableCell>{chapter.course_name}</TableCell>
                  <TableCell>{chapter.unit_name}</TableCell>
                  <TableCell>{chapter.chapter_name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={chapter.status}
                      onChange={() => handleStatusToggle(chapter._id, chapter.status)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton color="primary" component={Link} to={`/edit_chapter/${chapter._id}`}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">No chapters available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TablePagination
          component="div"
          count={totalItems}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChaptersTable;
