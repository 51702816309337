import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Modal,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import primg from "../../../assets/project1.png";
import primg2 from "../../../assets/project2.png";

const ProjectView = () => {
  const [pages] = useState([
    {
      id: 1,
      title: "Project Name",
      content: "Page 1 content...",
      background: "#ff4438",
      layout: "page1",
    },
    {
      id: 2,
      title: "Introduction of Project",
      content: "Page 2 content...",
      background: "#ff4438",
      layout: "page2",
    },
    {
      id: 3,
      title: "Background & Survey",
      content: "Page 3 content...",
      background: "#fff",
      layout: "page3",
    },
    {
      id: 4,
      title: "Idea Sketch",
      content: "Page 4 content...",
      background: "#fff",
      layout: "page4",
    },
    {
      id: 5,
      title: "Modules in Use",
      content: "Page 5 content...",
      background: "#fff",
      layout: "page5",
    },
    {
      id: 6,
      title: "Code",
      content: "Page 6 content...",
      background: "#fff",
      layout: "page6",
    },
    {
      id: 7,
      title: "Video",
      content: "Page 7 content...",
      background: "#fff",
      layout: "page7",
    },
    {
      id: 8,
      title: "Conclusion",
      content: "Page 8 content...",
      background: "#fff",
      layout: "page8",
    },
    {
      id: 9,
      title: "Thank You",
      content: "Page 9 content...",
      background: "#fff",
      layout: "page9",
    },
  ]);
  const rows = [
    {
      module: "ToF",
      role: "It recognizes the distance to the traffic light and ensures it follows the road straight.",
    },
    { module: "LED", role: "The role of the traffic light" },
    { module: "Display", role: "Shows the remaining time of the light" },
    { module: "Wheels", role: "It helps the car to run." },
    { module: "..", role: ".." },
    { module: "..", role: ".." },
    { module: "..", role: ".." },
  ];

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const currentPage = pages[currentPageIndex];

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  // Scroll handler to detect direction and change pages
  const handleScroll = (event) => {
    event.preventDefault(); // Prevent default scrolling behavior
    const scrollY = event.deltaY;

    if (scrollY > 0 && currentPageIndex < pages.length - 1) {
      // Scroll down -> Move to the next page
      setCurrentPageIndex((prev) => prev + 1);
    } else if (scrollY < 0 && currentPageIndex > 0) {
      // Scroll up -> Move to the previous page
      setCurrentPageIndex((prev) => prev - 1);
    }
  };

  // Add scroll event listener for both main view and fullscreen modal
  useEffect(() => {
    const container = document.querySelector("#scrollable-content");
    container.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleScroll);
    };
  }, [currentPageIndex]);

  const getPageContent = () => {
    switch (currentPage.layout) {
      case "page1":
        return (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: "#ff4a39",
              color: "white",
              padding: "40px",
              fontFamily: "'Arial', sans-serif",
            }}
          >
            <Box sx={{ mt: 8 }}>
              <Typography variant="h2" sx={{ mb: 2 }}>
                Project Name
              </Typography>
              <Typography variant="h5" sx={{ color: "black" }}>
                Team Name
              </Typography>
            </Box>
            <Box
              component="img"
              src={primg}
              alt="Bottom right image"
              sx={{
                position: "absolute",
                bottom: "-32px",
                right: "-30px",
                width: "56%",
              }}
            />
          </Box>
        );
      case "page2":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              backgroundColor: "#f14646",
            }}
          >
            <Box
              sx={{
                flex: 0.5,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  left: "20px",
                  color: "white",
                  fontSize: "34px",
                  fontWeight: "bold",
                }}
              >
                Introduction of Project
              </Typography>
              <img
                src={primg2}
                alt="Form image"
                style={{
                  position: "absolute",
                  bottom: "55px",
                  left: "313px",
                  width: "160px",
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  height: "500px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <form>
                  <div style={{ marginBottom: "20px" }}>
                    <label
                      style={{
                        fontSize: "16px",
                        display: "block",
                        marginBottom: "8px",
                      }}
                    >
                      Project Name
                    </label>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "2px solid red",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label
                      style={{
                        fontSize: "16px",
                        display: "block",
                        marginBottom: "8px",
                      }}
                    >
                      Reason for Creating the Project
                    </label>
                    <textarea
                      rows="4"
                      style={{
                        width: "100%",
                        height: "300px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "2px solid red",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                </form>
              </Box>
            </Box>
          </Box>
        );
      case "page5":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              padding: "20px",
            }}
          >
            <Box sx={{ flex: 1 }}>
              {/* Title */}
              <Typography variant="h4" gutterBottom>
                {currentPage.title}
              </Typography>

              {/* Table */}
              <TableContainer
                component={Paper}
                sx={{ maxWidth: "80%", margin: "0 auto", boxShadow: 3 }}
              >
                <Table>
                  {/* Table Head */}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: "#f14646",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "18px",
                          padding: "12px",
                        }}
                      >
                        Module / Materials
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "#f14646",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "18px",
                          padding: "12px",
                        }}
                      >
                        Role
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Table Body */}
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontWeight: index < 4 ? "bold" : "normal",
                            fontSize: "16px",
                            padding: "10px",
                          }}
                        >
                          {row.module}
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px", padding: "10px" }}>
                          {row.role}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        );
      case "page3":
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              backgroundColor: "white",
            }}
          >
            {/* Header */}
            <Typography
              variant="h3"
              sx={{
                fontSize: "48px",
                fontWeight: "bold",
                color: "black",
                marginBottom: "20px",
              }}
            >
              Background & Survey
            </Typography>

            {/* Subtext or additional content */}
          </Box>
        );
      case "page4":
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              backgroundColor: "white",
            }}
          >
            {/* Header */}
            <Typography
              variant="h3"
              sx={{
                fontSize: "48px",
                fontWeight: "bold",
                color: "black",
                marginBottom: "20px",
              }}
            >
              Idea Sketch
            </Typography>

            {/* Subtext or additional content */}
          </Box>
        );
      case "page6":
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              backgroundColor: "white",
            }}
          >
            {/* Header */}
            <Typography
              variant="h3"
              sx={{
                fontSize: "48px",
                fontWeight: "bold",
                color: "black",
                marginBottom: "20px",
              }}
            >
              Code
            </Typography>

            {/* Subtext or additional content */}
          </Box>
        );
      case "page7":
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              backgroundColor: "white",
            }}
          >
            {/* Header */}
            <Typography
              variant="h3"
              sx={{
                fontSize: "48px",
                fontWeight: "bold",
                color: "black",
                marginBottom: "20px",
              }}
            >
              Video
            </Typography>

            {/* Subtext or additional content */}
          </Box>
        );
      case "page8":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              backgroundColor: "#f14646",
            }}
          >
            {/* Left side for introduction text and image */}
            <Box
              sx={{
                flex: 0.5,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  left: "32px",
                  color: "white",
                  fontSize: "42px",
                  fontWeight: "bold",
                }}
              >
                Conclusion
              </Typography>
              <img
                src={primg2}
                alt="Form image"
                style={{
                  position: "absolute",
                  bottom: "55px",
                  left: "313px",
                  width: "160px",
                }}
              />
            </Box>

            {/* Right side for the form */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  height: "550px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <form>
                  <div style={{ marginBottom: "20px" }}>
                    <label
                      style={{
                        fontSize: "16px",
                        display: "block",
                        marginBottom: "8px",
                      }}
                    >
                      Main Idea
                    </label>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        padding: "10px",
                        height: "200px",
                        borderRadius: "10px",
                        border: "2px solid red",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label
                      style={{
                        fontSize: "16px",
                        display: "block",
                        marginBottom: "8px",
                      }}
                    >
                      Points To Improve
                    </label>
                    <textarea
                      rows="4"
                      style={{
                        width: "100%",
                        height: "200px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "2px solid red",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                </form>
              </Box>
            </Box>
          </Box>
        );
      case "page9":
        return (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: "#ff4a39", // Bright red background
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "'Arial', sans-serif",
            }}
          >
            {/* Centered "Thank You" Text */}
            <Typography
              variant="h1"
              sx={{
                fontSize: "64px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Thank You
            </Typography>

            {/* Bottom Left Contact Information */}
            <Box
              sx={{
                position: "absolute",
                bottom: "20px",
                left: "20px",
                fontSize: "14px",
              }}
            >
              <Typography>
                {/* www.korea.luxrobo.com
        <br />
        connect@luxrobo.com
        <br />
        +82 2 1544 8260
        <br />
        서울특별시 서초구 강남대로 311, 13층 (드림플러스 강남) */}
              </Typography>
            </Box>

            {/* Bottom Right Logos and Copyright */}
            <Box
              sx={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Logos */}
              <Box
                component="img"
                src={primg} // Replace primg with the correct image source path
                alt="Logo characters"
                sx={{
                  width: "100px",
                  height: "auto",
                  marginRight: "10px",
                }}
              />

              {/* Copyright */}
              <Typography sx={{ fontSize: "12px", textAlign: "right" }}>
                {/* © 2023 Luxrobo Co., Ltd. All Rights Reserved. */}
              </Typography>
            </Box>
          </Box>
        );
      default:
        return (
          <Typography variant="body1">Page content not available</Typography>
        );
    }
  };

  return (
    <Box sx={{ height: "85vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          backgroundColor: currentPage.background,
        }}
      >
        {/* Sidebar for navigation */}
        <Box
          sx={{
            width: "20%",
            borderRight: "1px solid #ddd",
            overflowY: "auto",
            padding: 2,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Pages
          </Typography>
          {pages.map((page, index) => (
            <Card
              key={page.id}
              sx={{
                mb: 2,
                cursor: "pointer",
                border:
                  currentPageIndex === index ? "2px solid #1976d2" : "none",
                backgroundColor: "#fff",
              }}
              onClick={() => setCurrentPageIndex(index)}
            >
              <CardContent>
                <Typography variant="body1">{page.title}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Main content view */}
        <Box
          id="scrollable-content"
          sx={{
            width: "80%",
            padding: 4,
            overflowY: "auto",
            backgroundColor: currentPage.background,
            position: "relative",
          }}
        >
          {getPageContent()}
        </Box>

        {/* Full-Screen Button */}
        <IconButton
          onClick={handleFullscreenToggle}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            backgroundColor: "#1976d2",
            color: "white",
          }}
        >
          {isFullscreen ? <CloseIcon /> : <FullscreenIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default ProjectView;
