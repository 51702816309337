import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import "./login.css";
import { Padding } from "@mui/icons-material";
import { Col, Container, Row } from "react-bootstrap";
import { getRole } from "../utils/AuthUtils";
import { jwtDecode } from "jwt-decode";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  Axios.defaults.withCredentials = true;

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/login`, // Use backticks here
        {
          username,
          password,
        }
      );

      if (response.data.status) {
        const token = response.data.token;
        console.log(response.data.token);
          localStorage.setItem('token_key', token);
          const decodedToken = jwtDecode(token);
          console.log("inside response"+decodedToken );
          const navigateTo = decodedToken.role_name || null;
        console.log(getRole());
        if (navigateTo) {
          navigate("/" + navigateTo);
        }
      } else {
        setErrorMessage(
          "Login failed: " + (response.data.message || "Unknown error")
        );
      }
    } catch (err) {
      setErrorMessage(
        "Login request failed: " + (err.message || "Unknown error")
      );
    }
  };

  return (
    <Row component="main" className="login-root">
      <Col item xs={12} sm={6} md={7} className="login-image-section">
        {/* <Box className="login-overlay"> */}
        {/* <Typography variant="h4" color="white" gutterBottom>
              Learning online is easier than ever before with LMS!
            </Typography> */}
        {/* </Box> */}
      </Col>
      <Col
        item
        xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={6}
        square
        className="login-form-section"
      >
        <Box className="login-box">
          <Typography component="h1" variant="h5">
            Welcome to Nano Skool!
          </Typography>
          {/* <Box display="flex" justifyContent="center" gap="20px" mt={2} mb={2}>
              <Avatar className="login-avatar">Admin</Avatar>
              <Avatar className="login-avatar">Instructor</Avatar>
            </Box> */}
          <form onSubmit={handleLogin} className="login-form">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Enter username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && (
              <Typography
                color="error"
                variant="body2"
                className="error-message"
              >
                {errorMessage}
              </Typography>
            )}
            <Link to="#" className="login-forgot-password mb-4">
              Forgot your password?
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="login-submit-button"
            >
              Login
            </Button>
            {/* <Link to="/register" className="login-register-link">
                Register
              </Link> */}
          </form>
        </Box>
      </Col>
    </Row>
  );
}
export default Login;
