import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Switch,
} from "@mui/material";

const EditChapter = () => {
  const { id } = useParams(); // Get chapter ID from the route
  const navigate = useNavigate();

  const [chapter, setChapter] = useState({
    class_id: "",
    course_name: "",
    unit_name: "",
    chapter_name: "",
    status: false,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    fetchChapterDetails();
  }, [id]);

  // Fetch the chapter details to pre-fill the form for editing
  const fetchChapterDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chapters/${id}`);
      const data = await response.json();
      if (data?.data) {
        setChapter({
          class_id: data.data.class_id,
          course_name: data.data.course_name,
          unit_name: data.data.unit_name,
          chapter_name: data.data.chapter_name,
          status: data.data.status,
        });
      } else {
        setError("Failed to fetch chapter details.");
      }
    } catch (err) {
      setError("Failed to fetch chapter details.");
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chapters/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(chapter),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update chapter.");
      }

      setSnackbarMessage("Chapter updated successfully");
      setOpenSnackbar(true);
      navigate("/chapters"); // Navigate back to chapters list after updating
    } catch (err) {
      setError(err.message || "Failed to update chapter.");
    }
  };

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChapter({
      ...chapter,
      [name]: value,
    });
  };

  const handleStatusChange = () => {
    setChapter({
      ...chapter,
      status: !chapter.status,
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <h2>Edit Chapter</h2>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <form onSubmit={handleFormSubmit}>
          <Box display="flex" flexDirection="column" gap={2} maxWidth="400px">
            <TextField
              label="Class"
              name="class_id"
              value={chapter.class_id}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Course Name"
              name="course_name"
              value={chapter.course_name}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Unit Name"
              name="unit_name"
              value={chapter.unit_name}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Chapter Name"
              name="chapter_name"
              value={chapter.chapter_name}
              onChange={handleInputChange}
              fullWidth
              required
            />
            {/* <Box display="flex" alignItems="center">
              <span>Status</span>
              <Switch
                checked={chapter.status}
                onChange={handleStatusChange}
                color="primary"
              />
            </Box> */}
            <Button type="submit" variant="contained" color="primary">
              Update Chapter
            </Button>
          </Box>
        </form>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditChapter;
