import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const AssignProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);


  useEffect(() => {
    fetchProducts();

  }, [page, rowsPerPage, searchTerm, startDate, endDate]);

  const fetchProducts = async () => {
    try {
      const token = getToken();
      if(!token){
        console.log("unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/allassignproduct?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        }
        // ?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`
        // `${process.env.REACT_APP_BACKEND}/api/teacher?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      const data = await response.json();
      console.log(data.data);
      
      if (data?.data) {
        const assignProductWithSerial = data.data.map((product, index) => ({
          ...product,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !product.status, // Add this line
        }));
        setProducts(assignProductWithSerial);
        setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No assign products found");
      }
    } catch (err) {
      console.error('Error assign products:', err);
      setError("Failed to fetch assign products");
    } finally {
      setLoading(false);
    }
  };

  const deleteTeacher = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this teacher?");
  
    if (!isConfirmed) {
      return; // Exit the function if the user cancels
    }
    
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!")
      }
      await fetch(`${process.env.REACT_APP_BACKEND}/api/assign_product/delete/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      setSnackbarMessage("Assign course deleted successfully");
      setOpenSnackbar(true);
      fetchProducts();
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateProductStatus(id, newStatus); // Update parent status in the backend
  };
  const updateProductStatus = async (id, status) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!")
        }
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/updatestatus/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchProducts(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAssignProduct =() =>{
    navigate("/school/assign-product")
  }

  // const editNavigation = (teacher)=>{
  //   navigate(`school/edit-assigned-course/${teacher._id}`)
  // }
  return (
    <Box>
      <h2>Assigned Product List</h2>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" padding={3} gap={1}>
          <TextField
            label="Search"
            variant="outlined"
            size="Large"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px" }} 
          />
          <Box flexGrow={1} /> {/* This will push the button to the right */}
          <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAssignProduct}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ height: "50px", backgroundColor: "#e0e0e0" }}
          >
            Assign
          </Button>
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Serial</b>
              </TableCell>
              <TableCell>
                <b>Resourse Person</b>
              </TableCell>
              <TableCell>
                <b>Department</b>
              </TableCell>
              <TableCell>
                <b>Product Name</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 ? (
              products.map((product) => (
                <TableRow key={product._id}>
                  <TableCell>{product.serial}</TableCell>
                  <TableCell>{product.name.name}</TableCell>
                  <TableCell>{product.department.department_name}</TableCell>
                  <TableCell>{product.product.product_name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={product.status}
                      onChange={() =>
                        handleStatusToggle(product._id, product.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>
                    {/* <IconButton
                      color="primary"
                      onClick={() => navigate(`${teacher._id}`)}
                      // onClick={() =>editNavigation(teacher)}
                    >
                      <EditIcon />
                    </IconButton> */}
                    <IconButton
                      color="secondary"
                      onClick={() => deleteTeacher(product._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  {/* ... other cells */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>No products available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignProductList;
