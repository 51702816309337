import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";

const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    let isMounted = true;

    axios
      .get(`${process.env.REACT_APP_BACKEND}/role/getAllRoles`)
      .then((response) => {
        if (isMounted) {
          const fetchedRoles = response.data;
          setRoles(fetchedRoles);
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    axios
      .get("${process.env.REACT_APP_BACKEND}/permission/getAllPermissions")
      .then((response) => {
        if (isMounted) {
          const fetchedPermissions = response.data;
          setPermissions(fetchedPermissions);
        }
      })
      .catch((error) => {
        console.error("Error fetching permissions:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleCheckboxChange = (event, permission) => {
    const { value, checked } = event.target;
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [value]: { permissionId: parseInt(value), status: checked ? 1 : 0 },
    }));
    console.log(selectedValues);
    
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/permission/updatestatus`, {
        permissions: Object.values(selectedValues),
      });

      if (response.status === 200) {
        alert("Permissions updated successfully!");
      } else {
        alert("An error occurred while updating permissions.");
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
      alert("An error occurred while updating permissions.");
    }
  };

  const groupedPermissions = {};
  Object.values(permissions).forEach((permission) => {
    if (!groupedPermissions[permission.role_id]) {
      groupedPermissions[permission.role_id] = {};
    }
    groupedPermissions[permission.role_id][permission.permission_name] = permission;
  });

  // Define the permission types you're interested in.
  const permissionTypes = ["view", "create", "update", "delete"];

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Role Permission Access
      </Typography>
      {roles.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Role</TableCell>
                {permissionTypes.map((type) => (
                  <TableCell key={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((role) => (
                <TableRow key={role.role_id}>
                  <TableCell>
                    {role.role_name.charAt(0).toUpperCase() +
                      role.role_name.slice(1)}
                  </TableCell>
                  {Object.entries(groupedPermissions[role.role_id] || {}).map(
                ([permissionName, permission]) => (
                    <TableCell key={permissionName}>
                      <Checkbox
                        name={permissionName}
                        value={permission.permission_id}
                        checked={
                          selectedValues[permission.permission_id]?.status === 1 ||
                          (selectedValues[permission.permission_id] === undefined &&
                            permission.status === 1)
                        }
                        onChange={(e) => handleCheckboxChange(e, permission)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
      <Box sx={{ marginTop: 4 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Apply Permissions
        </Button>
      </Box>
    </Box>
  );
};

export default RolePermissions;
