import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Collapse,
  Icon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoFull from "../assets/Logofull.png";
import logoIcon from "../assets/logoicon.png";
import { Link, Outlet, useNavigate } from "react-router-dom";
// import CategoryIcon from "@mui/icons-material/Category";
// import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
// import HandshakeIcon from '@mui/icons-material/Handshake';
import BusinessIcon from '@mui/icons-material/Business';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import PersonIcon from '@mui/icons-material/Person'; // For Teachers
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from "@mui/icons-material/Visibility";
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


import "./Layout.css";
import { getRole, removeToken } from "../utils/AuthUtils";
import { AssessmentOutlined, ClassOutlined, GroupOutlined, MenuBookOutlined } from "@mui/icons-material";

const drawerWidth = 300;
const collapsedWidth = 65;
const collapseScreenWidth = 1500;

const buttonPaths = ["/UploadContent", "/list", "/ProjectPage"];
const buttonLabels = [
  "Course",
  "Assessment",
  "Project",
  "Quiz",
  "Reports",
  "Tickets",
  "Task",
  "Settings",
];

const Layout = () => {
  const [role, setRole] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const navigate = useNavigate();

  // Handle screen resize to collapse sidebar when width is below 1502px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < collapseScreenWidth) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const role = getRole();
    setRole(role);

    const getMenuItemsByRole = (role) => {
      switch (role) {
        case "school":
          return [
            { text: "Dashboard", route: "school", icon: <DashboardIcon /> },
            {
              text: "Courses",
              icon: <MenuBookIcon />,
              subItems: [
                {
                  text: "View",
                  path: "/coursesTable",
                  icon: <VisibilityIcon />,
                },
                // {
                //   text: "Add Course",
                //   path: "/course",
                //   icon: <AddIcon />,
                // },
              ],
            },
            {
              text: "Teachers",
              icon: <PersonIcon />,
              subItems: [

                {
                  text: "View", path: "school/teachers",
                  icon: <VisibilityIcon />,
                },
                {
                  text: "Add Teacher", path: "school/add-teacher",
                  icon: <AddCircleOutlinedIcon />,
                },
                {
                  text: "Assigned Courses List", path: "school/assigned-courses-list",
                  icon: <VisibilityIcon />,
                },
                {
                  text: "Assign to Course", path: "school/assign-to-course",
                  icon: <VisibilityIcon />,
                },
              ],
            },
            {
              text: "Department",
              icon: <BusinessIcon />,
              subItems: [
                {
                  text: "View", path: "school/departments",
                  icon: <VisibilityIcon />,
                },
                { text: "List", path: "school/department-list" },
                {
                  text: "Assign Department",
                  path: "school/assigned-department-list/add",
                  icon: <AddCircleOutlinedIcon />,
                },
                {
                  text: "Assigned List",
                  path: "school/assigned-department-list",
                },
              ],
            },
            {
              text: "Class",
              icon: <SchoolIcon />,
              subItems: [{
                text: "View", path: "school/class",
                icon: <VisibilityIcon />,
              }],
            },
            {
              text: "Resourse Person",
              icon: <PersonIcon />,
              subItems: [
                {
                  text: "View", path: "school/resourse-person",
                  icon: <VisibilityIcon />,
                },
                {
                  text: "Add Resourse Person", Icon: <AddCircleOutlinedIcon />,
                  path: "school/add-resourse-person",
                  icon: <AddCircleOutlinedIcon />,
                },
                {
                  text: "Edit Resourse Person",
                  path: "school/edit-resourse-person",
                },
              ],
            },
            {
              text: "Student",
              icon: <EmojiPeopleIcon />,
              subItems: [
                {
                  text: "View", path: "/students",
                  icon: <VisibilityIcon />,
                },
                { text: "Add Student", path: "school/add-student", Icon: <AddCircleOutlinedIcon /> },
              ],
            },
            {
              text: "Academic",
              icon: <SchoolOutlinedIcon />,
              subItems: [
                { text: "Class", path: "school/class", Icon: <ClassOutlined /> },
                { text: "Course", path: "school/coursestable", Icon: <MenuBookOutlined /> },
                { text: "Department", path: "school/departments", Icon: <BusinessIcon /> },
                { text: "Chapters", path: "school/chapters", Icon: <MenuBookIcon /> },
              ],
            },
            {
              text: "Assigning",
              icon: <PanToolOutlinedIcon />,
              subItems: [
                { text: "To Teachers", path: "school/class", Icon: <PersonIcon /> },
                { text: "To Resourse Person", path: "school/coursestable", Icon: <GroupOutlined /> },
              ],
            },
            {
              text: "Parent",
              icon: <PersonIcon />,
              subItems: [
                { text: "View", path: "/parents", icon: <VisibilityIcon /> },
              ],
            },
          ];
        case "teacher":
          return [
            { text: "Students", route: "teacher/gradeassigned", icon: <EmojiPeopleIcon /> },
            { text: "Courses", route: "teacher/grade", icon: <MenuBookIcon /> },
            { text: "Courses", route: "teacher/course", icon: <MenuBookIcon /> },
            {
              text: "Report",
              icon: <AssignmentIcon />,
              subItems: [
                { text: "Unit Assesment", path: "teacher/unitassesment" },
                { text: "Chapter Assesment", path: "teacher/unitassesment" },
                { text: "Term Assesment", path: "teacher/unitassesment" },
                { text: "Participation", path: "teacher/gradeassigned" },
              ],
            },
            { text: "Projects", route: "teacher/project_sub", icon: <AssessmentOutlined /> },
          ];
        case "student":
          return [
            { text: "Content", path: "student/grade" },
            { text: "Project", path: "student/project_sub" },
            { text: "Assignment", path: "student/assignment_sub" },
            { text: "Teacer Comments", path: "student/teachercomment" },
            { text: "Reports", path: "student/gradeassigned" },
          ];
        case "parent":
          return [
            { text: "Dashboard", route: "parent", icon: <DashboardIcon /> },
            {
              text: "Students",
              route: "parent/students",
              icon: <SchoolIcon />,
            },
            { text: "Logout", icon: <LogoutIcon /> },
          ];
        default:
          return [
            { text: "Dashboard", route: "/dashboard", icon: <DashboardIcon /> },
          ];
      }
    };

    setMenuItems(getMenuItemsByRole(role));
  }, [role]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    removeToken();
    navigate("/");
  };


  const handleMenuClick = (menu) => {
    setOpenMenus((prev) => ({
      // Close all other menus and only open the clicked one
      [menu]: !prev[menu],
    }));
  };




  const renderSubItems = (subItems = [], level = 0) => {
    return subItems.map((subItem, index) =>
      subItem.subItems ? (
        <React.Fragment key={index}>
          <ListItem button onClick={() => handleMenuClick(subItem.text)}>
            <ListItemIcon sx={{ color: "black" }}>{subItem.icon}</ListItemIcon>
            <ListItemText primary={subItem.text} />
            <ExpandMoreIcon />
          </ListItem>
          <Collapse in={openMenus[subItem.text]} timeout="auto" unmountOnExit>
            <List sx={{ pl: level * 4 }}>
              {renderSubItems(subItem.subItems, level + 1)}
            </List>
          </Collapse>
        </React.Fragment>
      ) : (
        <ListItem
          button
          key={index}
          sx={{ marginLeft: "30px" }}
          component={Link}
          to={subItem.path}
        >
          <ListItemIcon sx={{ color: "black" }}>{subItem.icon}</ListItemIcon>
          <ListItemText primary={subItem.text} />
        </ListItem>
      )
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "#fff",
          boxShadow: "none",
          width: `calc(100% - ${collapsed ? collapsedWidth : drawerWidth}px)`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="#000"
            aria-label="menu"
            onClick={handleToggle}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>

          <Box
            className="top-bar-container"
            sx={{
              flexGrow: 1,
              overflowX: "auto",
              whiteSpace: "nowrap",
              display: "block",
              textAlign: "center",
              // padding: "10px",
            }}
          >
            {buttonLabels.map((text, index) => (
              <Button
                key={text}
                component={Link}
                to={buttonPaths[index]}
                color="inherit"
                sx={{
                  minWidth: "120px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  padding: "5px 10px",
                  color: "#000",
                }}
              >
                <PlayArrowIcon sx={{ marginRight: "3px", fontSize: "18px" }} />
                {text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: collapsed ? collapsedWidth : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: collapsed ? collapsedWidth : drawerWidth,
            backgroundColor: "#f9f9f9",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {!collapsed && (
          <Box sx={{ textAlign: "center", padding: 2 }}>
            <img src={logoFull} alt="Logo" style={{ width: "65%" }} />
          </Box>
        )}
        {collapsed && (
          <Box sx={{ textAlign: "center", padding: 1 }}>
            <img src={logoIcon} alt="Logo" style={{ width: "100%" }} />
          </Box>
        )}

        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List>
            {menuItems.map(({ text, route, icon, subItems }) => (
              <React.Fragment key={text}>
                {subItems ? (
                  <>
                    <ListItem button onClick={() => handleMenuClick(text)}>
                      <ListItemIcon sx={{ color: "black" }}>{icon}</ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: collapsed ? 0 : 1,
                          transition: "opacity 0.3s",
                        }}
                      />
                      <ExpandMoreIcon />
                    </ListItem>
                    <Collapse in={openMenus[text]} timeout="auto" unmountOnExit>
                      <List sx={{ pl: collapsed ? 0 : 4 }}>
                        {renderSubItems(subItems)}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItem button component={Link} to={route}>
                    <ListItemIcon sx={{ color: "black" }}>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </List>

        </Box>

        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon sx={{ color: "black" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              sx={{
                opacity: collapsed ? 0 : 1,
                transition: "opacity 0.3s",
              }}
            />
          </ListItem>
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 8, // Adjust based on the AppBar height
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default Layout;
