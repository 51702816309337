import React, { useState } from 'react';

const AddClassForm = () => {
  const [newClass, setNewClass] = useState({
    standard: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Function to handle form submission and POST the class data
  const addClass = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    fetch(`${process.env.REACT_APP_BACKEND}/api/class`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newClass),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status=="201") {
          setSuccess('Class added successfully!');
          setNewClass({ standard: '' });
        } else {
          setError('Failed to add class1.');
        }
        setLoading(false);
      })
      .catch(() => {
        setError('Failed to add class2.');
        setLoading(false);
      });
  };

  return (
    <div>
      <h2>Add New Class</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <form onSubmit={addClass}>
        <div>
          <label>Class Standard:</label>
          <input
            type="text"
            placeholder="Enter class standard"
            value={newClass.standard}
            onChange={(e) => setNewClass({ ...newClass, standard: e.target.value })}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Adding Class...' : 'Add Class'}
        </button>
      </form>
    </div>
  );
};

export default AddClassForm;
