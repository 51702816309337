import React, { useEffect, useState } from "react";
 
import {
 
  TextField,
 
  Button,
 
  Snackbar,
 
  Alert,
 
  Container,
 
  Grid,
 
  Typography,
 
  CircularProgress,
 
  IconButton,
 
} from "@mui/material";
 
import AddIcon from "@mui/icons-material/Add";
 
import DeleteIcon from "@mui/icons-material/Delete";
 
const Chapters = () => {
 
  const [classes, setClasses] = useState([]); // List of classes
 
  const [selectedClass, setSelectedClass] = useState(""); // Selected class
 
  const [courses, setCourses] = useState([]); // List of courses for selected class
 
  const [selectedCourse, setSelectedCourse] = useState(""); // Selected course
 
  const [unitName, setUnitName] = useState(""); // Unit name
 
  const [courseName, setCourseName] = useState(""); // Course name
 
  const [chapterFields, setChapterFields] = useState([{ chapterName: "" }]); // Array for multiple chapter fields
 
  const [loading, setLoading] = useState(false); // Loading state
 
  const [success, setSuccess] = useState(null); // Success message
 
  const [error, setError] = useState(null); // Error message
 
  // Fetch classes on component mount
 
  useEffect(() => {
 
    fetchClasses();
 
  }, []);
 
  // Fetch courses when a class is selected
 
  useEffect(() => {
 
    if (selectedClass) {
 
      fetchCourses(selectedClass);
 
    } else {
 
      setCourses([]);
 
      setSelectedCourse("");
 
    }
 
  }, [selectedClass]);
 
  const fetchClasses = async () => {
 
    setLoading(true);
 
    try {
 
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/class`);
 
      const data = await response.json();
 
      if (data?.data) {
 
        setClasses(data.data);
 
      } else {
 
        setError("Failed to fetch classes.");
 
      }
 
    } catch (err) {
 
      setError("Failed to fetch classes.");
 
    } finally {
 
      setLoading(false);
 
    }
 
  };
 
  const fetchCourses = async (classId) => {
 
    setLoading(true);
 
    try {
 
      const response = await fetch(
 
        `${process.env.REACT_APP_BACKEND}/api/course?class_id=${classId}`
 
      );
 
      const data = await response.json();
 
      if (data?.data) {
 
        setCourses(data.data);
 
      } else {
 
        setError("Failed to fetch courses.");
 
      }
 
    } catch (err) {
 
      setError("Failed to fetch courses.");
 
    } finally {
 
      setLoading(false);
 
    }
 
  };
 
  const handleSubmit = async (e) => {
 
    e.preventDefault();
 
    if (
 
      !selectedClass ||
 
      !selectedCourse ||
 
      !unitName ||
 
      !courseName ||
 
      chapterFields.some((field) => !field.chapterName)
 
    ) {
 
      setError("Please fill out all required fields.");
 
      return;
 
    }
 
    const chaptersData = chapterFields.map((field) => ({
 
      course_id: selectedCourse,
 
      class_id: selectedClass,
 
      course_name: courseName,
 
      unit_name: unitName,
 
      chapter_name: field.chapterName,
 
    }));
 
    setLoading(true);
 
    try {
 
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chapters`, {
 
        method: "POST",
 
        headers: { "Content-Type": "application/json" },
 
        body: JSON.stringify({ chapters: chaptersData }),
 
      });
 
      const data = await response.json();
 
      if (data?.success) {
 
        setSuccess("Chapters added successfully!");
 
        setError(null);
 
        setChapterFields([{ chapterName: "" }]);
 
        setSelectedClass("");
 
        setSelectedCourse("");
 
        setUnitName("");
 
        setCourseName("");
 
      } else {
 
        setError(data.message || "Failed to add chapters.");
 
      }
 
    } catch (err) {
 
      setError("Failed to add chapters.");
 
    } finally {
 
      setLoading(false);
 
    }
 
  };
 
  const handleAddChapter = () => {
 
    setChapterFields([...chapterFields, { chapterName: "" }]);
 
  };
 
  const handleChapterChange = (index, value) => {
 
    const newChapterFields = [...chapterFields];
 
    newChapterFields[index].chapterName = value;
 
    setChapterFields(newChapterFields);
 
  };
 
  const handleRemoveChapter = (index) => {
 
    setChapterFields(chapterFields.filter((_, i) => i !== index));
 
  };
 
  const handleSnackbarClose = () => {
 
    setSuccess(null);
 
    setError(null);
 
  };
 
  return (
 
    <Container
 
      maxWidth="xl"
 
      sx={{
 
 
        padding: "20px",
 
        borderRadius: "8px",
 
      }}
 
    >
 
      <Typography variant="h4" gutterBottom>
 
        Add New Chapters
 
      </Typography>
 
      {loading ? (
 
        <CircularProgress />
 
      ) : (
 
        <form onSubmit={handleSubmit}>
 
          <Grid container spacing={2}>
 
            <Grid item xs={12} md={6}>
 
              <TextField
 
                select
 
                label="Select Class"
 
                value={selectedClass}
 
                onChange={(e) => setSelectedClass(e.target.value)}
 
                fullWidth
 
                margin="normal"
 
                variant="outlined"
 
                required
 
                SelectProps={{
 
                  native: true,
 
                }}
 
              >
 
                <option value="">Select Class</option>
 
                {classes.map((classItem) => (
 
                  <option key={classItem._id} value={classItem._id}>
 
                    {classItem.standard}
 
                  </option>
 
                ))}
 
              </TextField>
 
            </Grid>
 
            <Grid item xs={12} md={6}>
 
              <TextField
 
                select
 
                label="Select Course"
 
                value={selectedCourse}
 
                onChange={(e) => setSelectedCourse(e.target.value)}
 
                fullWidth
 
                margin="normal"
 
                variant="outlined"
 
                required
 
                SelectProps={{
 
                  native: true,
 
                }}
 
                disabled={!selectedClass}
 
              >
 
                <option value="">Select Course</option>
 
                {courses.map((course) => (
 
                  <option key={course._id} value={course._id}>
 
                    {course.subject_name}
 
                  </option>
 
                ))}
 
              </TextField>
 
            </Grid>
 
            <Grid item xs={12} md={6}>
 
              <TextField
 
                label="Unit Name"
 
                value={unitName}
 
                onChange={(e) => setUnitName(e.target.value)}
 
                fullWidth
 
                margin="normal"
 
                variant="outlined"
 
                required
 
              />
 
            </Grid>
 
            <Grid item xs={12} md={6}>
 
              <TextField
 
                label="Course Name"
 
                value={courseName}
 
                onChange={(e) => setCourseName(e.target.value)}
 
                fullWidth
 
                margin="normal"
 
                variant="outlined"
 
                required
 
              />
 
            </Grid>
 
            {chapterFields.map((field, index) => (
 
              <Grid container spacing={2} key={index} alignItems="center">
 
                <Grid item xs={10}>
 
                  <TextField
 
                    label="Chapter Name"
 
                    value={field.chapterName}
 
                    onChange={(e) => handleChapterChange(index, e.target.value)}
 
                    fullWidth
 
                    margin="normal"
 
                    variant="outlined"
 
                    required
 
                  />
 
                </Grid>
 
                <Grid item xs={2}>
 
                  <IconButton
 
                    color="secondary"
 
                    onClick={() => handleRemoveChapter(index)}
 
                    aria-label="remove"
 
                  >
 
                    <DeleteIcon />
 
                  </IconButton>
 
                </Grid>
 
              </Grid>
 
            ))}
 
            <Grid item xs={12}>
 
              <Button
 
                type="button"
 
                variant="contained"
 
                startIcon={<AddIcon />}
 
                onClick={handleAddChapter}
 
                sx={{ marginBottom: 2 }}
 
              >
 
                Add Another Chapter
 
              </Button>
 
            </Grid>
 
            <Grid item xs={12}>
 
              <Button type="submit" variant="contained" color="primary">
 
                {loading ? <CircularProgress size={24} /> : "Add Chapters"}
 
              </Button>
 
            </Grid>
 
          </Grid>
 
        </form>
 
      )}
 
      <Snackbar
 
        open={Boolean(success) || Boolean(error)}
 
        autoHideDuration={6000}
 
        onClose={handleSnackbarClose}
 
      >
 
        <Alert
 
          onClose={handleSnackbarClose}
 
          severity={error ? "error" : "success"}
 
        >
 
          {error || success}
 
        </Alert>
 
      </Snackbar>
 
    </Container>
 
  );
 
};
 
export default Chapters;
 