import React from "react";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const lessons = [
  {
    unit: "Unit 1",
    title: "How solar panels convert sunlight into electricity",
    progress: 100,
    isActive: true,
  },
  {
    unit: "Unit 2",
    title: "How solar panels convert sunlight into electricity",
    progress: 100,
    isActive: true,
  },
  {
    unit: "Unit 3",
    title: "How solar panels convert sunlight into electricity",
    progress: 63,
    isActive: true,
  },
  {
    unit: "Unit 4",
    title: "How solar panels convert sunlight into electricity",
    progress: 0,
    isLocked: true,
  },
  // New chapter starts here
  {
    unit: "Unit 1",
    title: "The future of renewable energy",
    progress: 0,
    isLocked: true,
  },
  {
    unit: "Unit 2",
    title: "Sustainable energy sources",
    progress: 0,
    isLocked: true,
  },
];

const GradeView = () => {
  const handleLessonClick = (lesson) => {
    console.log(`Opening ${lesson}`);
    // Navigation or modal logic for opening the lesson goes here
  };

  const getUnitBgColor = (progress, isLocked) => {
    if (isLocked) return "#e0e0e0"; // Grey for locked units
    if (progress === 100) return "#f9f7f7"; // White for completed units
    return "#ffa726"; // Orange for ongoing units
  };

  const getTextColor = (progress, isLocked) => {
    return isLocked ? "#757575" : "#000"; // Grey text for locked, black otherwise
  };

  const isPlayButtonDisabled = (isLocked) => isLocked;

  const getTitleBgColor = (progress, isLocked) => {
    if (isLocked) return "#e0e0e0"; // Grey for locked units
    if (progress > 0 && progress < 100) return "#ffa726"; // Orange for ongoing progress
    return "#fff"; // Default white background
  };

  return (
    <Container maxWidth="xl" sx={{ padding: "20px" }}>
      {/* Top Header Section */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="left"
        sx={{ marginBottom: "5px" }} // Adding margin-bottom
      >
        <Grid item xs={1.2}>
          <Button
            variant="contained"
            color="error"
            sx={{
              borderRadius: "0",
              fontWeight: "bold",
              width: "100%",
              height: "62px",
              fontSize: "19px",
              marginBottom: "20px", // Add marginBottom for alignment
            }}
          >
            Grade 3
          </Button>
        </Grid>
        <Grid item xs={7.8}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#e4443f",
              color: "#fff",
              padding: "10px",
              fontSize: "27px",
              textAlign: "left",
              width: "100%",
              marginBottom: "5px", // Add marginBottom for alignment
            }}
          >
            Harnessing Renewable Energy
          </Typography>
          <Box sx={{ display: "flex", alignItems: "left", marginTop: "5px" }}>
            <LinearProgress
              variant="determinate"
              value={63}
              sx={{
                height: "5px",
                backgroundColor: "#f0f0f0",
                flexGrow: 1,
                borderRadius: "5px",
              }}
            />
            <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
              63%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/* Chapter 1 Title */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="left"
        sx={{ marginBottom: "5px" }} // Adding margin-bottom
      >
        <Grid item xs={1.2}>
          <Typography
            sx={{
              backgroundColor: "#ffeb99",
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "18px",
              width: "100%",
              marginBottom: "5px", // Add marginBottom for alignment
            }}
          >
            Chapter 1
          </Typography>
        </Grid>
        <Grid item xs={7.8}>
          <Typography
            sx={{
              backgroundColor: "#ffeb99",
              padding: "10px",
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "20px",
              width: "100%",
              marginBottom: "5px", // Add marginBottom for alignment
            }}
          >
            Solar Energy Basics
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/* Lessons List for Chapter 1 */}
      {lessons.slice(0, 4).map((lesson, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="left"
          key={index}
          sx={{ marginBottom: "10px" }}
        >
          {/* Unit */}
          <Grid item xs={1.2}>
            <Typography
              sx={{
                backgroundColor: getUnitBgColor(
                  lesson.progress,
                  lesson.isLocked
                ),
                color: getTextColor(lesson.progress, lesson.isLocked),
                padding: "10px",
                textAlign: "center",
                fontSize: "19px",
                width: "100%",
              }}
            >
              {lesson.unit}
            </Typography>
          </Grid>

          {/* Lesson Title and Progress */}
          <Grid item xs={7.8}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  color: lesson.isLocked ? "#bdbdbd" : "#000",
                  backgroundColor: getTitleBgColor(
                    lesson.progress,
                    lesson.isLocked
                  ),
                  fontSize: "19px",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                {lesson.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={lesson.progress}
                  sx={{
                    height: "5px",
                    backgroundColor: lesson.isLocked ? "#e0e0e0" : "#f0f0f0",
                    flexGrow: 1,
                    borderRadius: "5px",
                  }}
                />
                <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
                  {lesson.progress}%
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Play Button */}
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => handleLessonClick(lesson.title)}
              sx={{
                backgroundColor: lesson.isLocked ? "#e0e0e0" : "#f44336",
                color: lesson.isLocked ? "#bdbdbd" : "#fff",
                borderRadius: "5px",
                pointerEvents: isPlayButtonDisabled(lesson.isLocked)
                  ? "none"
                  : "auto",
                width: "100%",
              }}
            >
              <PlayArrowIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {/* Chapter 2 Title */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="left"
        sx={{ marginBottom: "5px" }} // Adding margin-bottom
      >
        <Grid item xs={1.2}>
          <Typography
            sx={{
              backgroundColor: "#ffeb99",
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "18px",
              width: "100%",
              marginBottom: "5px", // Add marginBottom for alignment
            }}
          >
            Chapter 2
          </Typography>
        </Grid>
        <Grid item xs={7.8}>
          <Typography
            sx={{
              backgroundColor: "#ffeb99",
              padding: "10px",
              fontWeight: "bold",
              textAlign: "left",
              width: "100%",
              fontSize: "20px",
              marginBottom: "5px", // Add marginBottom for alignment
            }}
          >
            Renewable Energy Future
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/* Lessons List for Chapter 2 */}
      {lessons.slice(4).map((lesson, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="left"
          key={index}
          sx={{ marginBottom: "10px" }}
        >
          {/* Unit */}
          <Grid item xs={1.2}>
            <Typography
              sx={{
                backgroundColor: getUnitBgColor(
                  lesson.progress,
                  lesson.isLocked
                ),
                color: getTextColor(lesson.progress, lesson.isLocked),
                padding: "10px",
                textAlign: "center",
                fontSize: "19px",
                width: "100%",
                marginBottom: "5px", // Add marginBottom for alignment
              }}
            >
              {lesson.unit}
            </Typography>
          </Grid>

          {/* Lesson Title and Progress */}
          <Grid item xs={7.8}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  color: lesson.isLocked ? "#bdbdbd" : "#000",
                  backgroundColor: getTitleBgColor(
                    lesson.progress,
                    lesson.isLocked
                  ),
                  fontWeight: lesson.isActive ? "bold" : "normal",
                  fontSize: "19px",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                {lesson.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={lesson.progress}
                  sx={{
                    height: "5px",
                    backgroundColor: lesson.isLocked ? "#e0e0e0" : "#f0f0f0",
                    flexGrow: 1,
                    borderRadius: "5px",
                  }}
                />
                <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
                  {lesson.progress}%
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Play Button */}
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => handleLessonClick(lesson.title)}
              sx={{
                backgroundColor: lesson.isLocked ? "#e0e0e0" : "#f44336",
                color: lesson.isLocked ? "#bdbdbd" : "#fff",
                borderRadius: "5px",
                pointerEvents: isPlayButtonDisabled(lesson.isLocked)
                  ? "none"
                  : "auto",
                width: "100%",
              }}
            >
              <PlayArrowIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Container>
  );
};

export default GradeView;
