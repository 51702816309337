import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const AddStudentPage = () => {
  const [student, setStudent] = useState({
    name: "",
    education: "",
    mobile: "",
    email: "",
    address: "",
    admission_Date: "",
    gender: "",
  });

  const [parent, setParent] = useState({
    parents_name: "",
    p_email: "",
    contact_num: "",
    relation_to_student: "",
  });

  const [profileImage, setProfileImage] = useState(null); // New state for image
  const [profileImagePreview, setProfileImagePreview] = useState(""); // State for the image preview
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", student.name);
    formData.append("education", student.education);
    formData.append("mobile", student.mobile);
    formData.append("email", student.email);
    formData.append("address", student.address);
    formData.append("admission_Date", student.admission_Date);
    formData.append("gender", student.gender);

    formData.append("parents_name", parent.parents_name);
    formData.append("p_email", parent.p_email);
    formData.append("contact_num", parent.contact_num);
    formData.append("relation_to_student", parent.relation_to_student);

    // Append profile image
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/student`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`, // Uncomment if using auth
            },
          }
        );

        if (response.status === 201) {
          setSnackbarMessage("Student and Parent added successfully");
          navigate("/students");
        } else {
          setSnackbarMessage("Failed to add Student");
        }

        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Please login to add student");
      }
    } catch (error) {
      console.error(error); // Log the actual error for debugging
      setSnackbarMessage("Error occurred");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Add New Student
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} item xs={7}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={student.name}
                onChange={(e) =>
                  setStudent({ ...student, name: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Education"
                value={student.education}
                onChange={(e) =>
                  setStudent({ ...student, education: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mobile"
                value={student.mobile}
                onChange={(e) =>
                  setStudent({ ...student, mobile: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={student.email}
                onChange={(e) =>
                  setStudent({ ...student, email: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                value={student.address}
                onChange={(e) =>
                  setStudent({ ...student, address: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Admission Date"
                type="date"
                value={student.admission_Date}
                onChange={(e) =>
                  setStudent({ ...student, admission_Date: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Gender</InputLabel>
                <Select
                  value={student.gender}
                  onChange={(e) =>
                    setStudent({ ...student, gender: e.target.value })
                  }
                  label="Gender"
                  required
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Profile Image
              </Typography>
              <Button variant="contained" component="label">
                Upload Profile Image
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {profileImage && (
                <div>
                  <Typography>{profileImage.name}</Typography>
                  <img
                    src={profileImagePreview}
                    alt="Profile Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                  />
                </div>
              )}
            </Grid>

            {/* Parent Fields */}
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Parent Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Parent Name"
                value={parent.parents_name}
                onChange={(e) =>
                  setParent({ ...parent, parents_name: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Parent Email"
                value={parent.p_email}
                onChange={(e) =>
                  setParent({ ...parent, p_email: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Parent Contact"
                value={parent.contact_num}
                onChange={(e) =>
                  setParent({ ...parent, contact_num: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Relation to Student"
                value={parent.relation_to_student}
                onChange={(e) =>
                  setParent({ ...parent, relation_to_student: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Add Student
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={
            snackbarMessage === "Student and Parent added successfully"
              ? "success"
              : "error"
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddStudentPage;
