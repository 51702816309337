import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  TextField,
  TablePagination,
  Button,
  IconButton,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SchoolIcon from "@mui/icons-material/School";
import Rating from "@mui/material/Rating";

import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const StudentPage = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortTerm, setSortTerm] = useState("grade-ascending"); // default sorting value
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0); // Initialize totalItems as number
  const [selectedGrade, setSelectedGrade] = useState({
    grade: "",
    gradeDivision: "",
  });
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    fetchStudents();
  }, [page, rowsPerPage, searchTerm]);

  const fetchStudents = async () => {
    // setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data);
        console.log(data.data);
        
        setTotalItems(data.pagination.totalItems); // Correctly set totalItems
      } else {
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchgradebySchool = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/grades/schoolData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gradeData = response.data.data;
        setGrades(gradeData);
        console.log("gradeData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };
    fetchgradebySchool();
  }, []);

  useEffect(() => {
    const fetchstudentBygradedivision = async () => {
      if (selectedGrade.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchStudents();
        return;
      }
      if (!selectedGrade.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/grades/${selectedGrade.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data;
        setStudents(students);
        console.log("students: ", response.data.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [selectedGrade.gradeDivision]);

  const deleteStudent = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/student/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSnackbarMessage("Student deleted successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to delete student");
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      const newStatus = !currentStatus;

      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}/status_student`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSnackbarMessage("Student status updated successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to update student status");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page to 0 when search term changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box padding={3}>
      <Typography variant="h4" component="h1" gutterBottom>
        Students
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              label="Search"
              variant="outlined"
              size="large"
              onChange={handleSearch}
              value={searchTerm}
              sx={{ width: "350px" }}
            />

            <FormControl margin="normal" sx={{ minWidth: 350 }}>
              <InputLabel>Select Grade</InputLabel>
              <Select
                value={selectedGrade.grade}
                onChange={(e) => {
                  const selectedGrade1 = grades.find(
                    (item) => item._id === e.target.value
                  );

                  if (selectedGrade1 && selectedGrade1.grade_id) {
                    // Check if grade_id exists before accessing it
                    const gradeDivision = `${
                      selectedGrade1.grade_id?.gradename || ""
                    } ${selectedGrade1.division || ""}`; // Safely access gradename and division

                    setSelectedGrade({
                      grade: e.target.value,
                      gradeDivision, // Assign the combined grade division
                    });
                  } else {
                    // Handle case where grade_id or selectedGrade1 is undefined
                    setSelectedGrade({
                      grade: e.target.value,
                      gradeDivision: "", // Set gradeDivision to an empty string if undefined
                    });
                  }
                }}
                label="Grade"
                aria-required="true"
              >
                <MenuItem value="">Select All</MenuItem>
                {grades.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.grade_id ? item.grade_id.gradename : "Unknown Grade"}{" "}
                    {item.division ? item.division : "Unknown Division"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box flexGrow={1} />
            <Button
              component={Link}
              to="/add-student"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Student
            </Button>
          </Box>
        </Grid>
        <Grid container spacing={3} padding={3}>
  {students.length > 0 ? (
    students.map((student) => {
      // Split grade_division into grade and division
      const [_, grade, division] = student.grade_division
        ? student.grade_division.split(" ")
        : ["", "N/A", "N/A"];

      return (
        <Grid item xs={12} sm={6} md={4} key={student._id}>
          <Card
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
            }}
          >
            {/* Top Section: Name, ID, Grade, and Division */}
            <Box sx={{ backgroundColor: "#e0dfda", padding: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* Student Name */}
                <Typography variant="h5" sx={{ fontWeight: "bold", color: "#000" }}>
                  {student.name}
                </Typography>
              </Box>

              {/* ID, Grade, Division */}
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                <Typography
                  sx={{
                    backgroundColor: "#a0947c",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {`ID-${student.student_id}`}
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: "#a0947c",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {`Grade-${grade}`}
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: "#a0947c",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {`Division-${division}`}
                </Typography>
              </Box>
            </Box>

            {/* Bottom Section: Profile Picture, Details, and Rating */}
            <Box sx={{ backgroundColor: "#fff", padding: 2 }}>
              {/* Profile Picture & Details */}
              <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
                {/* Profile Picture */}
                <Box sx={{ marginRight: "2px" }}>
                  <img
                    src={student.profile_url || "/default-profile.png"}
                    alt={`${student.name}'s profile`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "1px solid #333",
                      marginRight: "15px",
                    }}
                  />
                </Box>

                {/* Details and Rating aligned vertically */}
                <Box flexGrow={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {/* Details */}
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <MailIcon
                        sx={{
                          fontSize: "22px",
                          mr: 1,
                          color: "#fff",
                          backgroundColor: "#a0947c",
                          padding: "4px",
                          borderRadius: "10px",
                        }}
                      />
                      <Typography variant="body2">{student.email}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <PhoneIcon
                        sx={{
                          fontSize: "22px",
                          mr: 1,
                          color: "#fff",
                          backgroundColor: "#a0947c",
                          padding: "4px",
                          borderRadius: "10px",
                        }}
                      />
                      <Typography variant="body2">{student.mobile}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <SchoolIcon
                        sx={{
                          fontSize: "22px",
                          mr: 1,
                          color: "#fff",
                          backgroundColor: "#a0947c",
                          padding: "3px",
                          borderRadius: "10px",
                        }}
                      />
                      <Typography variant="body2">{student.totalCourses || "N/A"}</Typography>
                    </Box>
                  </Box>

                  {/* Rating */}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <ThumbUpIcon
                      sx={{
                        fontSize: "22px",
                        mr: 1,
                        color: "#fff",
                        backgroundColor: "#a0947c",
                        padding: "3px",
                        borderRadius: "10px",
                      }}
                    />
                    <Rating
                      name="student-rating"
                      value={student.rating || 3}
                      precision={0.5}
                      readOnly
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      );
    })
  ) : (
    <p>No students Available</p>
  )}
</Grid>

        {totalItems > rowsPerPage && (
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[6, 12, 24]}
              labelRowsPerPage="Items per page"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StudentPage;
