import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Switch,
  Grid,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import ReactQuill from "react-quill";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import { useLocation } from "react-router-dom";

const Assessment = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const [assessment_name, setAssessment_name] = useState("");
  const [expiretimee, setExpiretime] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [statusToggle, setStatusToggle] = useState("0");
  const [assessmentId, setAssessmentId] = useState("")
  const [loading, setLoading] = useState(false); // New loading state

  const { unitId,grade_namee, coursenamee, chapterindexx, chaptername, unitnamee } =
    location.state || {};

  useEffect(() => {
    fetchAssessmentQuestions();
  }, [statusToggle]);

  const fetchAssessmentQuestions = async () => {
    try {
      const assessmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentname/${id}`
      );
      // Extracting the data from the response
      // const { status, message, data } = assessmentResponse.data;
      const { message, data } = assessmentResponse.data;

      // Check if data is not empty
      if (data.length > 0) {
        const assessmentDetails = data[0]; // Accessing the first element in the data array
        console.log("Assessment_deatailss:",assessmentDetails);
        
        // Now you can access the properties directly
        const assessmentTitle = assessmentDetails.assessment_title;
        const totalScore = assessmentDetails.total_score;
        console.log("Assessment Title:", assessmentTitle);
        console.log("Total Score:", totalScore);
        setAssessment_name(assessmentTitle);
        const hours = Math.floor(assessmentDetails.expire_time / 3600);
        const minutes = Math.floor(
          (assessmentDetails.expire_time % 3600) / 60
        );
        const seconds = assessmentDetails.expire_time % 60;
        const setTime = `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
        setExpiretime(setTime);
        setStatusToggle(assessmentDetails.status);

        
        console.log("assessment status:",assessmentDetails.status);
        setAssessmentId(assessmentDetails.assessment_id)

        
      } else {
        console.log("No assessment data found.");
      }

      console.log("Unit id:", id);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/byunitId/${id}`
      );
      const assessmentData = response.data.data || [];
      setAssessmentQuestions(
        Array.isArray(assessmentData) ? assessmentData : []
      );
    } catch (error) {
      console.error("Error fetching assessment questions:", error);
    }

  };

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleSubmit = async () => {
    const formattedAnswers = assessmentQuestions.map((question, index) => ({
      [index]: selectedAnswers[index] || "", // Only the selected answer or an empty string
    }));

    let totalPoints = 0;

    assessmentQuestions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];

      // Calculate points only for "yes/no" and "mcq" types
      if (
        question.question_type === "mcq" ||
        question.question_type === "yes/no"
      ) {
        if (selectedAnswer === question.answer) {
          totalPoints += question.points; // Add points if the answer is correct
        }
      }
      // For "descriptive" questions, points are always 0, so no action needed.
    });

    console.log("Formatted Answers:", formattedAnswers);
    console.log("Total Points:", totalPoints);
  };

  // useEffect(()=>{
  //   console.log(
  //     "statussss: ", statusToggle
  //   );
    
  // },[statusToggle])

  // const handleStatusToggle = async() => {
  //   const newStatus = !statusToggle; // Toggle the current status
  //   await updateAssessmentStatus(assessmentId, newStatus);
  //   setStatusToggle(newStatus);
  //   // Use the functional update form of setStatusToggle
  // // setStatusToggle(prevStatus => {
  // //   const newStatus = !prevStatus; // Toggle the status
  // //   updateAssessmentStatus(assessmentId, newStatus); // Pass the updated status to the backend
  // //   return newStatus; // Update the local state
  // // });
  // };

  // const updateAssessmentStatus = async (id, newStatus) => {
  //   const token = getToken();
  //   console.log("the id present in status parament:",id);
  //   console.log("the sending data: ",id, newStatus, unitId)
    
  //   if(!token){
  //     console.log("Access denied!");
  //     return;
  //   }
  //   setLoading(true); // Set loading to true before API call

  //   try {
  //     await fetch(
  //       `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentEnable`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Authorization": `Bearer ${token}`, // Fix the template string
  //         },
  //         body: JSON.stringify({
  //           assessmentId,    // Pass the assessmentId
  //           statusToggle:newStatus,    // Pass the statusToggle (true/false)
  //           unitId,          // Pass the unitId
  //         }), // Properly stringify the body object
  //       }
  //     );
  //     setSnackbarMessage("Assessment status updated successfully");
  //     setOpenSnackbar(true);
  //     fetchAssessmentQuestions(); // Refresh the table after status change
  //   } catch (err) {
  //     setError("Failed to update Assessment status");
  //   }finally {
  //     setLoading(false); // Set loading to false after API call finishes
  // }
  // };

  const updateAssessmentStatus = async (id, newStatus) => {
    console.log("Updating status to: ", newStatus); // Log the newStatus
    const token = getToken();
    if (!token) {
        console.log("Access denied!");
        return;
    }
    try {
        await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentEnable`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  assessmentId,  
                    newStatus,  // Pass the correct value (1 or 0)
                    unitId,
                }),
            }
        );
        setSnackbarMessage("Assessment status updated successfully");
        setOpenSnackbar(true);
        fetchAssessmentQuestions(); // Refresh the assessment data
    } catch (err) {
        setError("Failed to update Assessment status");
    }
};


//   const handleToggleChange = (event) => {
//     const newStatus = event.target.checked;  // Now it's a boolean

//     // Update the local state immediately to reflect the change in UI
//     setStatusToggle(newStatus);

//     // Call the function to update assessment status with the new value
//     updateAssessmentStatus(assessmentId, newStatus ? 1 : 0);  // Convert to number when sending to API
// };

const handleToggleStatus = async (id, status) => {
  const token = getToken();
    if (!token) {
        console.log("Access denied!");
        return;
    }
    console.log("unnnit id: ",unitId);
  try {
    // await axios.put(`${process.env.REACT_APP_BACKEND}/api/product/${id}`, { status: status === 1 ? 0 : 1 });

    await axios.put(
      `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentEnable`, // API URL
      {
        assessmentId: id,  // Send the assessment ID
        // status: status === 1 ? 0 : 1,  // Send the updated status (1 or 0)
        status:statusToggle,
        unitId: unitId,  // Send the unit ID
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in headers
        },
      }
    );
            fetchAssessmentQuestions();
  } catch (error) {
    console.error("Error toggling status", error);
  }
};


  return (
    <Container maxWidth="lg">
      {/* Breadcrumb Section */}
      <Box sx={{ backgroundColor: "#9e503d", height: "50px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "18px", color: "#fff !important" }}
            >
              {grade_namee}
              <ArrowRightIcon sx={{color:'#fff'}}/>
              {coursenamee}
              <ArrowRightIcon sx={{color:'#fff'}}/>
              Chapter {chapterindexx} :{chaptername}: {unitnamee}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f1f1",
          height: "100px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          marginBottom: "30px",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="h3" gutterBottom>
            {assessment_name}
          </Typography>
        </Container>
      </Box>
  <Box
    sx={{
      backgroundColor: "#f1f1f1", // check the color or adjust it
      height: "auto",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px", // add spacing if needed
      marginBottom: "20px", // adjust for visual spacing
      justifyContent: "space-between",
    }}
  >
    <p>Expire Time: {expiretimee}</p>
    <p>
      Enable
      <Switch
  checked={statusToggle}
  onChange={() => handleToggleStatus(id, statusToggle)}
        color="primary"
        disabled={loading} 
      />
    </p>
  </Box>

      {/* MCQ Section */}
      {Array.isArray(assessmentQuestions) && assessmentQuestions.length > 0 ? (
        assessmentQuestions.map((q, index) => (
          <Box key={index} mb={2}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              {`${index + 1}. ${q.question_text}`}
            </Typography>
            {q.question_type === "mcq" && (
              <div name={`question-${index}`}>
                {q.options.map((option, i) => (
                  <div
                    key={i}
                    style={{
                      margin: "8px 0",
                      cursor: "pointer",
                      padding: "8px",
                      backgroundColor:
                        q.answer === option ? "#21932f" : "#f0f0f0",
                      borderRadius: "4px",
                      color: q.answer === option ? "#fff" : "#000",
                    }}
                    onClick={() => handleAnswerChange(index, option)}
                  >
                    {String.fromCharCode(65 + i)}. {option}{" "}
                    {/* Display letters A, B, C, D followed by the option */}
                  </div>
                ))}
              </div>
            )}

            {q.question_type === "yes/no" && (
              <div
                style={{
                  margin: "8px 0",
                  padding: "8px",
                  // backgroundColor: q.answer === "Yes" ? "#21932f" : q.answer === "No" ? "#d32f2f" : "",
                  borderRadius: "4px",
                }}
              >
                Answer: {q.answer} {/* Display the answer directly */}
              </div>
            )}

            {q.question_type === "descriptive" && (
              <textarea
                style={{
                  width: "100%",
                  border: "1px solid #ccc", // Light gray border
                  borderRadius: "4px", // Rounded corners
                  padding: "10px", // Padding for inner spacing
                  fontSize: "16px", // Font size for readability
                  minHeight: "100px", // Minimum height for the textarea
                  resize: "vertical", // Allow vertical resizing only
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                  outline: "none", // Remove default outline
                  transition: "border-color 0.2s", // Smooth transition for border color
                }}
                value={q.answer} // Use q.answer if no answer has been selected
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                onFocus={(e) => (e.target.style.borderColor = "#33c7ff")} // Change border color on focus
                onBlur={(e) => (e.target.style.borderColor = "#ccc")} // Reset border color on blur
              />
            )}
          </Box>
        ))
      ) : (
        <Typography>No questions available.</Typography>
      )}

      {/* Submit Button */}
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Answers
        </Button>
      </Box>
    </Container>
  );
};

export default Assessment;
