import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const StudentTable = ({ students = [], onViewProfile, onEdit, onDelete }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Roll Number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableCell>12</TableCell>
          <TableCell>22</TableCell>
          <TableCell>Nandhu</TableCell>
          <TableCell>Nandhu@gmail.com</TableCell>
          <TableCell>
            <IconButton color="primary" aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </TableCell>
          {students.length > 0 ? (
            students.map((student) => (
              <TableRow key={student.id}>
                {/* <TableCell>{student.id}</TableCell>
                <TableCell>{student.rollNumber}</TableCell>
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.email}</TableCell> */}

                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => onEdit(student.id)}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => onDelete(student.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>No students available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentTable;
