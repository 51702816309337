// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .save-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  
  .saved-content {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    min-height: 100px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pages/quill/EditorComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,iBAAiB;EACnB","sourcesContent":[".editor-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n  \n  .save-button {\n    margin-top: 10px;\n    padding: 8px 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .save-button:hover {\n    background-color: #0056b3;\n  }\n  \n  .saved-content {\n    margin-top: 20px;\n    border: 1px solid #ddd;\n    padding: 10px;\n    border-radius: 4px;\n    min-height: 100px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
