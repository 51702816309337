// src/api/schoolApi.js

const API_URL = `${process.env.REACT_APP_BACKEND}/api/school`; // Adjust the URL as needed

// Fetch all schools
// Fetch all schools
export const fetchSchools = async () => {
    try {
        const response = await fetch(API_URL);
        if (!response.ok) throw new Error('Failed to fetch schools');
        const result = await response.json();
        // Check if result.data is an array
        if (!Array.isArray(result.data)) {
            throw new Error('Data is not an array');
        }
        return result.data; // Return the array of schools
    } catch (error) {
        console.error('Error fetching schools:', error);
        throw error;
    }
};

// // Add a new school
// export const addSchool = async (schoolData) => {
//     try {
//         const response = await fetch(API_URL, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify(schoolData)
//         });
//         if (!response.ok) throw new Error('Failed to add school');
//         return await response.json();
//     } catch (error) {
//         console.error('Error adding school:', error);
//         throw error;
//     }
// }
// SchoolServices.js

export const addSchool = async (schoolData) => {
    try {
        // if (!schoolData || typeof schoolData !== 'object') {
        //     throw new Error('Invalid school data provided');
        // }

        const response = await fetch(API_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(schoolData),
        });

        if (!response.ok) {
            const result = await response.json();
            throw new Error(result.message || 'Failed to add school');
        }

        return await response.json();
    } catch (error) {
        console.error('Error adding school:', error);
        throw error;
    }
};

// Update a school by ID
export const updateSchool = async (id, schoolData) => {
    try {
        const response = await fetch(`${API_URL}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(schoolData)
        });
        if (!response.ok) throw new Error('Failed to update school');
        return await response.json();
    } catch (error) {
        console.error('Error updating school:', error);
        throw error;
    }
};

// Delete a school by ID
export const deleteSchool = async (id) => {
    try {
        const response = await fetch(`${API_URL}/${id}`, {
            method: 'DELETE'
        });
        if (!response.ok) throw new Error('Failed to delete school');
        return await response.json();
    } catch (error) {
        console.error('Error deleting school:', error);
        throw error;
    }
};

// Soft delete a school
export const softDeleteSchool = async (id) => {
    try {
        const response = await fetch(`${API_URL}/${id}`, {
            method: 'PATCH', // Use PATCH for updates
            headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) throw new Error('Failed to soft delete school');
        return await response.json();
    } catch (error) {
        console.error('Error soft deleting school:', error);
        throw error;
    }
};
