import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const Teacher = () => {
  const location = useLocation();

  // Example teacher data
  const teacherName = "John Doe";
  const subject = "Mathematics";

  // Check if the current route is the root of the teacher path
  const isRootPath = location.pathname === "/teacher";

  return (
    <div className="teacher-container">
      {isRootPath ? (
        <>
          <h1>Teacher Dashboard</h1>
          <p>Welcome, {teacherName}</p>
          <p>Your subject: {subject}</p>
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default Teacher;
