import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Alert,
  Grid,
  Typography,
  Container,
  Button
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

const divisions = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"]; // Available divisions

const ClassList = () => {
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState({});

  useEffect(() => {
    const fetchUserClasses = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const response = await fetch('http://localhost:8080/api/class', { // Adjusted endpoint if needed
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          const userClasses = data.data.reduce((acc, { standard, division }) => {
            const classKey = `Grade ${standard}`;
            if (!acc[classKey]) acc[classKey] = [];
            acc[classKey].push(division);
            return acc;
          }, {});
          setSelectedClasses(userClasses);
        } else {
          setError("Failed to fetch user classes");
        }
      } catch (error) {
        setError("Error fetching user classes");
      } finally {
        setLoading(false);
      }
    };

    fetchUserClasses();
  }, []);

  const handleCheckboxChange = (className, division) => (event) => {
    const { checked } = event.target;
    setSelectedClasses((prev) => {
      const selectedDivisions = prev[className] || [];
      return {
        ...prev,
        [className]: checked
          ? [...selectedDivisions, division]
          : selectedDivisions.filter((div) => div !== division),
      };
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const response = await fetch('http://localhost:8080/api/class', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(selectedClasses),
      });
      if (response.ok) {
        setSnackbarMessage("Changes saved successfully");
        setOpenSnackbar(true);
      } else {
        setError("Failed to save changes");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setError("Failed to save changes");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Select Grade and Divisions
      </Typography>
      <Box sx={{ border: "1px solid #ccc", borderRadius: 2, padding: 3 }}>
        <FormGroup>
          <Grid container spacing={2}>
            {Array.from({ length: 12 }, (_, index) => {
              const className = `Grade ${index + 1}`;
              return (
                <Grid item xs={12} key={index}>
                  <Typography variant="h6" gutterBottom>
                    {className}
                  </Typography>
                  <Grid container spacing={1}>
                    {divisions.map((division) => (
                      <Grid item xs={2} key={division}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={`${className}-${division}`}
                              checked={
                                selectedClasses[className]?.includes(division) || false
                              }
                              onChange={handleCheckboxChange(className, division)}
                            />
                          }
                          label={division}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </Box>
      <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} className="mt-2">
        Save Changes
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ClassList;
