import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  Grid,
  Avatar,
  FormControl,
  InputLabel,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

// Sample messages data
const messages = [
  {
    id: 1,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "These questions aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
  {
    id: 2,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "These questions aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
  {
    id: 3,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "Aim to assess student engagement, learning outcomes, and interest in robotics, providing valuable feedback for improving the program.",
  },
];

// Top section buttons data
// const topButtons = [
//   { label: "7 Grades", color: "#e4443f" },
//   { label: "450 Students", color: "#e4443f" },
//   { label: "10 Courses", color: "#e4443f" },
// ];

// Message Card Component
const MessageCard = ({ user, courseName, profile, time, text, isReply }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: 2,
        width: "100%",
      }}
    >
      {!isReply && (
        <Avatar
          alt={user}
          // src="https://randomuser.me/api/portraits/women/65.jpg"
          src={profile}
          sx={{ width: 48, height: 48, marginRight: 2 }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: isReply ? "row-reverse" : "row",
          gap: 2,
          alignItems: "center",
          padding: 2,
          backgroundColor: "#f4f4f4",
          borderRadius: "8px",
          width: "100%",
          position: "relative",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {isReply && (
          <Avatar
            alt={user}
            src="https://randomuser.me/api/portraits/women/65.jpg"
            sx={{ width: 48, height: 48 }}
          />
        )}

        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle2" fontWeight="bold">
              {user.toUpperCase()}
            </Typography>
            <Typography variant="subtitle2" fontWeight="bold">
              {courseName.toUpperCase()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {time}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ marginTop: "4px" }}>
            {text}
          </Typography>
        </Box>

        <ChatBubbleOutlineIcon
          sx={{
            position: "absolute",
            top: "-8px",
            left: isReply ? "unset" : "-24px",
            right: isReply ? "-24px" : "unset",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "2px",
            border: "1px solid #ccc",
            fontSize: "24px",
            color: "#666",
          }}
        />
      </Box>
    </Box>
  );
};

// Main TeacherComment Component
const TeacherComment = () => {
  const [comments, setComments] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
          setLoading(false); // Stop loading if no token
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacherComments`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // timeout: 1000 // 5 seconds timeout
          }
        );
        const commentsData = response.data.data || [];
        setComments(commentsData);
        setLoading(true);
        console.log("commentsData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };
    fetchComments();
  }, []);

  useEffect(()=>{
    console.log("selected teacher:  ",selectedTeacher);
    
  })

  // fetch teachers for select box
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
          // setLoading(false); // Stop loading if no token
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacherComments/teachers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // timeout: 1000 // 5 seconds timeout
          }
        );
        const teacherData = response.data.data || [];
        setTeachers(teacherData);
        // setLoading(true);
        console.log("commentsData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };
    fetchTeachers();
  }, []);

  // get comments by teacherId
  useEffect(() => {
    const fetchCommentsByTeacher = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
          setLoading(false); // Stop loading if no token
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacherComments/teachers/${selectedTeacher}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // timeout: 1000 // 5 seconds timeout
          }
        );
        const commentData = response.data.data || [];
        console.log(commentData);
        
        setComments(commentData);
        setLoading(true);
        console.log("commentsData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };
    fetchCommentsByTeacher();
  }, [selectedTeacher]);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <h2>Teacher Comments</h2>
        <FormControl margin="normal" sx={{ minWidth: 350 }}>
  <InputLabel>Select teacher</InputLabel>
  <Select
    value={selectedTeacher}
    onChange={(e) => setSelectedTeacher(e.target.value)}
    label="Select teacher"
    aria-required="true"
  >
    <MenuItem value="">Select All</MenuItem>
    {Array.from(
      new Map(teachers.map((item) => [item.teacher_id?._id, item])) // Use Map to ensure uniqueness based on teacher_id
    ).map(([teacherId, teacher]) => (
      <MenuItem key={teacherId} value={teacherId}>
        {teacher.teacher_id ? teacher.teacher_id.name : "Unknown Teacher"}
      </MenuItem>
    ))}
  </Select>
</FormControl>
        {/* <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#000",
            width: "73%",
            fontWeight: "bold",
            backgroundColor: "#e4e1de",
            padding: "6px",
            textAlign: "center",
          }}
        >
          PRODUCT ASSIGNED TO{" "}
        </Typography> */}

        {/* Top Buttons */}
        {/* <Box sx={{ display: "flex", gap: 2 }}>
          {topButtons.map((button, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: button.color,
                color: "#fff",
                fontSize: "17px",
                padding: "8px 10px",
                textAlign: "center",
                width: "130px",
                borderRadius: "4px",
              }}
            >
              {button.label}
            </Box>
          ))}
        </Box> */}
      </Box>

      {/* Dropdown Selectors */}
      {/* <Grid container spacing={3} marginBottom={2}>
        <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Grade</MenuItem>
            <MenuItem value={1}>Grade 1</MenuItem>
            <MenuItem value={2}>Grade 2</MenuItem>
          </Select>
        </Grid> */}

      {/* <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ height: "45px" }}
          >
            <MenuItem value="">Select Division</MenuItem>
            <MenuItem value="A">Division A</MenuItem>
            <MenuItem value="B">Division B</MenuItem>
          </Select>
        </Grid> */}

      {/* <Grid item xs={4}>
          <Select
            fullWidth
            displayEmpty
            defaultValue=""
            sx={{ backgroundColor: "#f4e3c9" }}
          >
            <MenuItem value="">
              <em>Select Student</em>
            </MenuItem>
            <MenuItem value="Math">Math</MenuItem>
            <MenuItem value="Science">Science</MenuItem>
          </Select>
        </Grid>
      </Grid> */}

      {/* Messages Section */}
      <Box>
        {comments.length > 0 ? (
          comments.map((msg, index) => (
            <MessageCard
              key={msg._id}
              user={msg.teacher_id.name}
              courseName={msg.courses_name}
              profile={msg.teacher_id.profile_url}
              time={msg.createdAt}
              text={msg.comment}
              // isReply={index % 2 === 1} // Alternating reply and normal message
            />
          ))
        ) : (
          <p>No comments found</p>
        )}
      </Box>
    </Box>
  );
};

export default TeacherComment;
