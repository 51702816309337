import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const AddTeacher = () => {
  const navigate = useNavigate();
  const [newTeacher, setNewTeacher] = useState({
    teacher_id: "",
    name: "",
    qualification: "",
    mobile: "",
    email: "",
    hire_Date: "",
    subject_specialization: "",
    teacherCategory: "",
    teacherSubCategory: "",
  });

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  const [qualifications] = useState([
    "Diploma in Elementary Education (D.El.Ed)",
    "Bachelor of Elementary Education (B.El.Ed)",
    "Bachelor of Education (B.Ed)",
    "Master of Education (M.Ed)",
    "Teacher Eligibility Test (TET)",
    "Central Teacher Eligibility Test (CTET)",
    "State Eligibility Test (SET)",
    "National Eligibility Test (NET)",
    "Ph.D.",
    "Diploma in Education (D.Ed)",
    "Postgraduate Diploma in Education (PGDE)",
    "Master of Philosophy (M.Phil) in Education",
    "Certificate in Guidance (CIG)",
    "Special Education Certificate",
    "Bachelor of Physical Education (B.P.Ed)",
    "Master of Physical Education (M.P.Ed)"
  ]);

  // Fetch teacher categories
  useEffect(() => {
    const fetchTeacherCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teachercategory`);
        setTeacherCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchTeacherCategories();
  }, []);

  // Fetch teacher subcategories based on selected category
  useEffect(() => {
    const fetchTeacherSubCategories = async () => {
      if (!newTeacher.teacherCategory) return; // Skip if no category is selected
      console.log(newTeacher.teacherCategory);
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teacher/sub/${newTeacher.teacherCategory}`);
        setTeacherSubCategories(response.data.data);
        // Filter subcategories based on the selected category
        setFilteredSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher sub-categories:", error);
        setError("Failed to fetch teacher sub-categories");
      }
    };
    fetchTeacherSubCategories();
  }, [newTeacher.teacherCategory]); // Depend on teacherCategory

  // Update filtered subcategories when subcategories data changes
  useEffect(() => {
    if (newTeacher.teacherCategory) {
      setFilteredSubcategories(
        teacherSubCategories.filter(sub => sub.teacher_category_id === newTeacher.teacherCategory)
      );
    }
  }, [teacherSubCategories, newTeacher.teacherCategory]);

  const addTeacher = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Access Denied. Please log in.");
        return;
      }
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/teacher`, newTeacher, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage("Teacher added successfully");
      setOpenSnackbar(true);
      setNewTeacher({
        teacher_id: "",
        name: "",
        qualification: "",
        mobile: "",
        email: "",
        hire_Date: "",
        subject_specialization: "",
        teacherCategory: "",
        teacherSubCategory: "",
      });
      navigate("/teachers");
    } catch (err) {
      console.error("Error adding teacher:", err);
      setError("Failed to add teacher");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Add New Teacher
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={addTeacher}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={newTeacher.name}
                onChange={(e) => setNewTeacher({ ...newTeacher, name: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal" variant="outlined" required>
                <InputLabel>Qualification</InputLabel>
                <Select
                  value={newTeacher.qualification}
                  onChange={(e) => setNewTeacher({ ...newTeacher, qualification: e.target.value })}
                  label="Qualification"
                  aria-required="true"
                >
                  {qualifications.map((qualification, index) => (
                    <MenuItem key={index} value={qualification}>
                      {qualification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel>Teacher Category</InputLabel>
              <Select
                value={newTeacher.teacherCategory}
                onChange={(e) => setNewTeacher({ ...newTeacher, teacherCategory: e.target.value })}
                label="Teacher Category"
                aria-required="true"
              >
                {teacherCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.teacher_name}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined" required>
                <InputLabel>Teacher Sub Category</InputLabel>
                <Select
                  value={newTeacher.teacherSubCategory}
                  onChange={(e) => setNewTeacher({ ...newTeacher, teacherSubCategory: e.target.value })}
                  label="Teacher Sub Category"
                  aria-required="true"
                  disabled={!newTeacher.teacherCategory}  // Disable if no category is selected
                >
                  {filteredSubcategories.map((subCategory) => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.teacher_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mobile"
                type="text"
                value={newTeacher.mobile}
                onChange={(e) => setNewTeacher({ ...newTeacher, mobile: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                inputProps={{ pattern: "[0-9]{10}" }}
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                value={newTeacher.email}
                onChange={(e) => setNewTeacher({ ...newTeacher, email: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Hire Date"
                type="date"
                value={newTeacher.hire_Date}
                onChange={(e) => setNewTeacher({ ...newTeacher, hire_Date: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Add Teacher
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddTeacher;
