import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Container,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const AssessmentsList = () => {
  const [assessments, setAssessments] = useState([]);
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [formData, setFormData] = useState({
    assessment_name: "",
    course_id: "",
    chapter_id: "",
    class_id: "",
    levels: "",
    due_date: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    fetchAssessments();
    fetchClasses();
    fetchCourses();
    fetchChapters();
  }, [page, rowsPerPage, searchTerm]);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      setAssessments(response.data.data);
    } catch (error) {
      setError("Failed to fetch assessments");
    } finally {
      setLoading(false);
    }
  };

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/classes`);
      setClasses(response.data.data);
    } catch (error) {
      setError("Failed to fetch classes");
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`);
      setCourses(response.data.data);
    } catch (error) {
      setError("Failed to fetch courses");
    }
  };

  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters`);
      setChapters(response.data.data);
    } catch (error) {
      setError("Failed to fetch chapters");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/assessment/${id}`);
      setSnackbarMessage("Assessment deleted successfully");
      setOpenSnackbar(true);
      fetchAssessments();
    } catch (error) {
      setError("Failed to delete assessment");
    }
  };

  // const handleUpdate = (assessment) => {
  //   setSelectedAssessment(assessment);
  //   setFormData({
  //     assessment_name: assessment.assessment_name || "",
  //     course_id: assessment.course_id || "",
  //     chapter_id: assessment.chapter_id || "",
  //     class_id: assessment.class_id || "",
  //     levels: assessment.levels || "",
  //     due_date: assessment.due_date || "",
  //   });
  // };
  const handleUpdate = (id) => {
    // Navigate to the update page with the question ID
    navigate(`/update-assessment/${id}`);
  };

  const handleUpdateAssessment = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedAssessment._id}`,
        formData
      );
      setSnackbarMessage("Assessment updated successfully");
      setOpenSnackbar(true);
      setSelectedAssessment(null);
      setFormData({
        assessment_name: "",
        course_id: "",
        chapter_id: "",
        class_id: "",
        levels: "",
        due_date: "",
      });
      fetchAssessments();
    } catch (error) {
      setError("Failed to update assessment");
    }
  };

  const handleCreateNew = () => {
    navigate("/assessment");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  return (
    <div className="mt-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateNew}
        >
          Create New Assessment
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Chapter</TableCell>
              <TableCell>Assessment Title</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.length > 0 ? (
              assessments.map((assessment, index) => (
                <TableRow key={assessment._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {classes.find((c) => c._id === assessment.class_id)
                      ?.standard || "N/A"}
                  </TableCell>
                  <TableCell>
                    {courses.find((c) => c._id === assessment.course_id)
                      ?.course_name || "N/A"}
                  </TableCell>
                  <TableCell>
                    {chapters.find((c) => c._id === assessment.chapter_id)
                      ?.course_name || "N/A"}
                  </TableCell>
                  <TableCell>{assessment.assessment_name}</TableCell>
                  <TableCell>
                    {moment(assessment.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleUpdate(assessment)}
                      startIcon={<EditIcon />}
                    >
                      Update
                    </Button>{" "} */}
                    <Button
            variant="outlined"
            color="info"
            onClick={() => handleUpdate(assessment._id)}
            startIcon={<AddIcon />}
          >
            Update
          </Button>{" "}
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDelete(assessment._id)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>{" "}
                    <Button
            variant="outlined"
            color="info"
            onClick={() => navigate(`/questions/${assessment._id}`)} // Redirect to questions page with assessment_id
            startIcon={<AddIcon />}
          >
            View Questions
          </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>No assessments available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TablePagination
          component="div"
          count={assessments.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AssessmentsList;