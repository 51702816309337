import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
  CircularProgress, Container, Box, IconButton, Switch, TextField, MenuItem
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
 
const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [departmentCategorySubs, setDepartmentCategorySubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [isEdit, setIsEdit] = useState(false);
 
  useEffect(() => {
    fetchDepartments();
    fetchDepartmentCategories();
    fetchDepartmentCategorySubs();
  }, []);
 
  // Fetch Departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
      console.log(response.data); // Log the data to check its structure
      if (Array.isArray(response.data.data)) {
        setDepartments(response.data.data);
      } else {
        console.error('Expected an array but received:', response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching departments", error);
      setError(error);
      setLoading(false);
    }
  };
 
  // Fetch Department Categories
  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category`);
      setDepartmentCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };
 
  // Fetch Department Category Subs
  const fetchDepartmentCategorySubs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category_sub`);
      setDepartmentCategorySubs(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department category subs", error);
    }
  };
 
  // Handle Save or Update
  const handleSave = async () => {
    try {
      if (isEdit) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${currentDepartment._id}`, currentDepartment);
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/department`, currentDepartment);
      }
      fetchDepartments();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving department", error);
    }
  };
 
  // Delete Department
  const handleDelete = async () => {
    if (departmentToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/department/${departmentToDelete._id}`);
        fetchDepartments();
        handleCloseConfirmDialog();
      } catch (error) {
        console.error("Error deleting department", error);
      }
    }
  };
 
  // Toggle Status
  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${id}`, { status: status === 1 ? 0 : 1 });
      fetchDepartments();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };
 
  // Open Dialog for Add/Edit
  const handleOpenDialog = (department) => {
    setCurrentDepartment(department || {});
    setIsEdit(!!department);
    setOpenDialog(true);
  };
 
  // Close Dialog
  const handleCloseDialog = () => {
    setCurrentDepartment({});
    setOpenDialog(false);
  };
 
  // Open Confirmation Dialog
  const handleOpenConfirmDialog = (department) => {
    setDepartmentToDelete(department);
    setConfirmDelete(true);
  };
 
  // Close Confirmation Dialog
  const handleCloseConfirmDialog = () => {
    setDepartmentToDelete(null);
    setConfirmDelete(false);
  };
 
  if (loading) return <CircularProgress />;
 
  if (error) return <Typography color="error">Error loading data: {error.message}</Typography>;
 
  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" gutterBottom>
          Department List
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
          Add Department
        </Button>
      </Box>
 
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>HOD</TableCell>
              <TableCell>Contact No</TableCell>
              <TableCell>WhatsApp No</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>No of Labs</TableCell>
              <TableCell>Lab Location</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department, index) => (
              <TableRow key={department._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{department.department_name}</TableCell>
                <TableCell>{department.department_hod}</TableCell>
                <TableCell>{department.contact_no}</TableCell>
                <TableCell>{department.whatsapp_no}</TableCell>
                <TableCell>{department.email_address}</TableCell>
                <TableCell>{department.no_of_labs}</TableCell>
                <TableCell>{department.lab_location}</TableCell>
                <TableCell>
                  <Switch
                    checked={department.status === 1}
                    onChange={() => handleToggleStatus(department._id, department.status)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(department)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleOpenConfirmDialog(department)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
 
      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEdit ? 'Edit Department' : 'Add Department'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Department Name"
            fullWidth
            margin="normal"
            value={currentDepartment.department_name || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, department_name: e.target.value })
            }
          />
          <TextField
            label="HOD"
            fullWidth
            margin="normal"
            value={currentDepartment.department_hod || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, department_hod: e.target.value })
            }
          />
          <TextField
            label="Contact No"
            fullWidth
            margin="normal"
            value={currentDepartment.contact_no || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, contact_no: e.target.value })
            }
          />
          <TextField
            label="WhatsApp No"
            fullWidth
            margin="normal"
            value={currentDepartment.whatsapp_no || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, whatsapp_no: e.target.value })
            }
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={currentDepartment.email_address || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, email_address: e.target.value })
            }
          />
          <TextField
            label="No of Labs"
            fullWidth
            margin="normal"
            type="number"
            value={currentDepartment.no_of_labs || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, no_of_labs: e.target.value })
            }
          />
          <TextField
            label="Lab Location"
            fullWidth
            margin="normal"
            value={currentDepartment.lab_location || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, lab_location: e.target.value })
            }
          />
          <TextField
            select
            label="Department Category"
            fullWidth
            margin="normal"
            value={currentDepartment.department_category || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, department_category: e.target.value })
            }
          >
            {departmentCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Department Subcategory"
            fullWidth
            margin="normal"
            value={currentDepartment.department_subcategory || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, department_subcategory: e.target.value })
            }
          >
            {departmentCategorySubs.map((subcategory) => (
              <MenuItem key={subcategory._id} value={subcategory._id}>
                {subcategory.department_sub_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Status (1 for Active, 0 for Inactive)"
            fullWidth
            margin="normal"
            type="number"
            value={currentDepartment.status || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, status: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {isEdit ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
 
      {/* Confirmation Dialog for Deletion */}
      <Dialog open={confirmDelete} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this department?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
 
export default DepartmentList;