import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import './EditorComponent.css'; // Import your CSS file

const EditorComponent = ({ onChange }) => {
  const quillRef = useRef(null); // Use useRef to store Quill instance
  const [savedContent, setSavedContent] = useState(''); // State to store saved content

  // Image handler
  const imageHandler = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const quill = quillRef.current.getEditor(); // Access Quill instance

          // Ensure the editor is focused before manipulating the selection
          quill.focus();

          const range = quill.getSelection();
          const length = quill.getLength();
          const index = range ? range.index : length - 1;

          // Insert the image at the current cursor position or at the end
          quill.insertEmbed(index, 'image', reader.result);

          // Move the cursor after the image
          quill.setSelection(index + 1);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  // Handle save button click
  const handleSave = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      setSavedContent(quill.root.innerHTML); // Save the editor content
      // console.log(savedContent);
      
      onChange && onChange(quill.root.innerHTML); // Optional: Notify parent component
    }
  };

  return (
    <div className="editor-container">
      <ReactQuill
        ref={quillRef} // Set Quill instance to the ref
        defaultValue="" // Use defaultValue instead of value
        onBlur={(range, source, editor) => onChange && onChange(editor.getHTML())} // Track changes on blur
        theme="snow"
        modules={{
          toolbar: {
            container: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline'],
              [{ align: [] }],
              ['link', { image: imageHandler }], // Add image handler
            ],
          },
        }}
        formats={EditorComponent.formats}
      />
      <button onClick={handleSave} className="save-button">Save</button>

      {/* Display the saved content as raw HTML */}
      <h3>Saved Content (HTML):</h3>
      <pre className="saved-content">{savedContent}</pre>

      {/* Display saved content rendered */}
      <h3>Rendered Saved Content:</h3>
      <div dangerouslySetInnerHTML={{ __html: savedContent }} />
    </div>
  );
};

EditorComponent.formats = [
  'header',
  'font',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'align',
  'link',
  'image',
];

export default EditorComponent;
