import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";

const TeachersListPage = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  useEffect(() => {
    fetchTeachers();
  }, [page, rowsPerPage, searchTerm, startDate, endDate]);

  const fetchTeachers = async () => {
    try {
      const response = await fetch(
        `http://localhost:8080/api/teacher?page=${page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}&start_date=${startDate ? new Date(startDate).toISOString() : ""
        }&end_date=${endDate ? new Date(endDate).toISOString() : ""}`
        // `${process.env.REACT_APP_BACKEND}/api/teacher?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      const data = await response.json();
      if (data?.data) {
        const teachersWithSerial = data.data.map((teacher, index) => ({
          ...teacher,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !teacher.status, // Add this line
        }));
        setTeachers(teachersWithSerial);
        setSerialNumbers(teachersWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  const deleteTeacher = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`, {
        method: "DELETE",
      });
      setSnackbarMessage("Teacher deleted successfully");
      setOpenSnackbar(true);
      fetchTeachers();
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const toggleStatus = async (id) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/toggle-status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: statusToggle }),
        }
      );

      fetchTeachers(); // Refresh the teachers list
    } catch (err) {
      setError("Failed to toggle teacher status");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchTeachers(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };
  return (
    <Box>
      <h2>Teachers</h2>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" padding={3} gap={1} >
          <TextField
            label="Search"
            variant="outlined"
            size="Large"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px",}}
          />
          <Box display="flex" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Box>
          <Box flexGrow={1} /> {/* This will push the button to the right */}
          <Button
            component={Link}
            to="/add-teacher"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{height:"50px"}}
          >
            Add Teacher
          </Button>
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Serial</b>
              </TableCell>
              <TableCell>
                <b>Teacher ID</b>
              </TableCell>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Qualification</b>
              </TableCell>
              <TableCell>
                <b>Mobile</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Hire Date</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 ? (
              teachers.map((teacher) => (
                <TableRow key={teacher._id}>
                  <TableCell>{teacher.serial}</TableCell>
                  <TableCell>{teacher.teacher_id}</TableCell>
                  <TableCell>{teacher.name}</TableCell>
                  <TableCell>{teacher.qualification}</TableCell>
                  <TableCell>{teacher.mobile}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  {/* <TableCell>{teacher.P_email}</TableCell> */}
                  <TableCell>
                    {new Date(teacher.hire_Date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={teacher.status}
                      onChange={() =>
                        handleStatusToggle(teacher._id, teacher.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/edit-teacher/${teacher._id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => deleteTeacher(teacher._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No teachers available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TeachersListPage;
